import { useState, ChangeEvent } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ModalTriggerButton from "../PopupTriggerButton";
import UserSearchModal from "./UserSearchModal";

import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import isModalOpenAtom from "../../recoil/isModalOpen";
import { useMutation } from "@tanstack/react-query";
import { postNotification } from "../../api/Notification";

export interface User {
  id: number;
  email: string;
  mallName: string;
  registrationNumber: string;
}

interface FormData {
  priority: "" | "URGENT" | "IMPORTANT" | "GENERAL";
  type: "" | "NOTICE" | "SETTLEMENT";
  message: string;
}

interface Props {
  closeModal?: () => void;
  refreshProps: () => void;
}

const NotificationSendModal = ({ closeModal, refreshProps }: Props) => {
  // ==================================================================================================================
  // 리코일 스테이트
  // ==================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ==================================================================================================================
  // 컴포넌트 스테이트
  // ==================================================================================================================
  const [receivers, setReceivers] = useState<User[]>([]);
  const [labelWithCounter, setLabelWithCounter] =
    useState(`보낼 메세지 ( 0 / 100 )`);

  // ==================================================================================================================
  // 리액트 훅 폼
  // ==================================================================================================================
  const { control, handleSubmit, register } = useForm<FormData>({
    defaultValues: {
      priority: "",
      type: "",
      message: ""
    }
  });

  // ==================================================================================================================
  // 수신자 추가 함수
  // ==================================================================================================================
  const addReceivers = (selectedUsers: User[]) => {
    setReceivers((prev) => {
      const uniqueData = selectedUsers.filter((selectedUser) => {
        const duplicateData = prev.find(
          (prevUser) => prevUser.id === selectedUser.id
        );
        return !duplicateData;
      });
      return [...prev, ...uniqueData];
    });
  };

  // ==================================================================================================================
  // 수신자 제거 함수
  // ==================================================================================================================
  const removeReceiver = (user: any) => {
    setReceivers((prev) =>
      prev.filter((selectedUser) => selectedUser.id !== user.id)
    );
  };

  // ==================================================================================================================
  // 새 알림 보내기 Mutate
  // ==================================================================================================================
  const { mutate } = useMutation(postNotification, {
    // 성공시 -> 모달 닫기, 화면 갱신 이벤트 호출, 성공 토스트 팝업
    onSuccess: () => {
      closeModal?.();
      refreshProps();
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "알림 메세지 전송에 성공하였습니다"
      });
    },
    // 실패시 -> 모달 닫기, 에러 모달 팝업
    onError: (error: any, variables, context) => {
      closeModal?.();
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "알림 메세지 전송에 실패하였습니다"
      });
    }
  });

  // ==================================================================================================================
  // 전송 버튼을 눌렀을 때
  // ==================================================================================================================
  const onValid = (formData: FormData) => {
    // 수신자 선택 이외의 밸리데이션은 리액트 훅 폼에서 처리한다
    // 수신자 선택 밸리데이션 적용
    if (receivers.length < 1) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "수신자를 1명 이상 선택해 주세요"
      });
      return;
    }

    // 메세지 글자 제한 밸리데이션
    if (formData.message.length > 100) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "보낼 메세지는 100자를 넘을 수 없습니다"
      });
      return;
    }

    const userIds = receivers.map((user) => user.id);
    const requestParams = { ...formData, userIds };

    mutate(requestParams);
  };

  // ==================================================================================================================
  // 글자수 세기
  // ==================================================================================================================
  const changeLabel = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const messages = e.target.value;
    const counter = messages.length;
    setLabelWithCounter(`보낼 메세지 ( ${counter} / 100 )`);
  };

  return (
    <Dialog open={true} maxWidth={"sm"} fullWidth={true}>
      <DialogTitle>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          새 알림 보내기
          <IconButton type="button" onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <form onSubmit={handleSubmit(onValid)}>
        <DialogContent>
          <Stack spacing={2}>
            <Box>
              <Stack direction={"row"} spacing={2}>
                <Controller
                  name="priority"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="우선 순위"
                      required
                      size="medium"
                      select
                      value={value}
                      onChange={onChange}
                      fullWidth
                    >
                      <MenuItem value="URGENT">긴급</MenuItem>
                      <MenuItem value="IMPORTANT">중요</MenuItem>
                      <MenuItem value="GENERAL">일반</MenuItem>
                    </TextField>
                  )}
                />
                <Controller
                  name="type"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="알림 타입"
                      required
                      size="medium"
                      select
                      value={value}
                      onChange={onChange}
                      fullWidth
                    >
                      <MenuItem value="NOTICE">공지</MenuItem>
                      <MenuItem value="SETTLEMENT">정산</MenuItem>
                    </TextField>
                  )}
                />
              </Stack>
            </Box>

            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{ width: "100%" }}
            >
              <Accordion
                sx={{
                  flex: 6,
                  border: "1px solid rgba(0, 0, 0, 0.22)",
                  borderRadius: "4px",
                  boxShadow: "unset"
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>수신자: {receivers.length}명 선택됨</Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ borderTop: "1px solid rgba(0, 0, 0, .125)" }}
                >
                  <Grid container wrap={"wrap"} spacing={1}>
                    {receivers.map((user) => (
                      <Grid key={user.id} item>
                        <Chip
                          variant="outlined"
                          // icon={<FaceIcon />}
                          label={user.email}
                          deleteIcon={<DeleteIcon />}
                          onDelete={() => removeReceiver(user)}
                        ></Chip>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Stack sx={{ flex: 1 }}>
                <ModalTriggerButton
                  color={"primary"}
                  modal={<UserSearchModal addReceiverEvent={addReceivers} />}
                  sx={{
                    ml: 1,
                    py: 1.5,
                    borderRadius: "4px",
                    fontSize: "0.8rem",
                    "&:hover": {
                      background: "#FF6634",
                      color: "white",
                      cursor: "pointer"
                    }
                  }}
                >
                  추가
                </ModalTriggerButton>
              </Stack>
            </Stack>
            <TextField
              {...register("message", {
                required: true,
                maxLength: {
                  value: 100,
                  message: "보낼 메세지는 100자를 넘을 수 없습니다"
                }
              })}
              label={labelWithCounter}
              required
              multiline
              rows={5}
              inputProps={{ maxLength: 100 }}
              onChange={(e) => changeLabel(e)}
            ></TextField>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            type={"submit"}
            size={"large"}
            color={"primary"}
            fullWidth={true}
            variant={"contained"}
            sx={{
              color: "white",
              mx: 2,
              mb: 2
            }}
          >
            전송
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NotificationSendModal;
