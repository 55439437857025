import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  getPurchasesDetail,
  getPurchasesDetailExcel
} from "../../api/v2/Purchases";
import { PurchaseDetailData } from "../../api/v2/Purchases/types";
import excelDownload from "../../libs/excel-download";
import { addCommas } from "../../libs/thousands-commas";
import isModalOpenAtom from "../../recoil/isModalOpen";
import { PurchasesDetailState } from "../../recoil/purchases/atom";
import LoadingButton from "../LoadingButton";
import MuiTable from "../Table/MuiTable";
import Paper from "@mui/material/Paper";

interface PaymentColumnHeadCell {
  id: keyof PurchaseDetailData;
  label: string;
  isTotalData: boolean;
  isSort: boolean;
}

const columns: PaymentColumnHeadCell[] = [
  {
    id: "mallId",
    label: "가맹점 ID",
    isTotalData: true,
    isSort: false
  },
  {
    id: "mallName",
    label: "가맹점명",
    isTotalData: false,
    isSort: false
  },
  {
    id: "businessName",
    label: "사업자 등록증 상호명",
    isTotalData: false,
    isSort: false
  },
  {
    id: "merchantGrade",
    label: "거래등급",
    isTotalData: false,
    isSort: false
  },
  {
    id: "createdAt",
    label: "거래 일자",
    isTotalData: true,
    isSort: true
  },
  {
    id: "receiveDate",
    label: "매입 일자",
    isTotalData: false,
    isSort: false
  },
  {
    id: "paymentFromCardCompany",
    label: "입금예정일자",
    isTotalData: false,
    isSort: false
  },
  {
    id: "approvalNum",
    label: "승인 번호",
    isTotalData: false,
    isSort: false
  },
  {
    id: "cardCompany",
    label: "카드사",
    isTotalData: false,
    isSort: false
  },
  {
    id: "status",
    label: "거래 상태",
    isTotalData: false,
    isSort: false
  },
  {
    id: "receivePrice",
    label: "거래금액",
    isTotalData: true,
    isSort: false
  },
  {
    id: "cardFee",
    label: "카드사 수수료",
    isTotalData: false,
    isSort: false
  },
  {
    id: "purchasePrice",
    label: "입금예정금액",
    isTotalData: false,
    isSort: false
  },
  {
    id: "quota",
    label: "할부기간",
    isTotalData: false,
    isSort: false
  },
  {
    id: "paymentKey",
    label: "거래고유 KEY",
    isTotalData: false,
    isSort: false
  }
];

const PurchaseDetail = ({ totalData }: any) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);
  const [purchasesDetail, setPurchasesDetail] =
    useRecoilState(PurchasesDetailState);

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 쿼리 키, 쿼리 함수, 옵션 의 세가지 파라미터를 받는다
  const { data } = useQuery(
    ["/purchase/detail", purchasesDetail],
    () => getPurchasesDetail(purchasesDetail),
    {
      keepPreviousData: true,
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      },
      select: (data) => ({
        ...data,
        content: data.content.map((purchaseData: PurchaseDetailData) => ({
          ...purchaseData,
          purchasePrice: `${addCommas(purchaseData.purchasePrice)}`,
          cardFee: `${addCommas(purchaseData.cardFee)}`,
          receivePrice: `${addCommas(purchaseData.receivePrice)}`
        }))
      })
    }
  );

  // ===================================================================================================================
  // 엑셀 다운로드
  // ===================================================================================================================
  const { refetch } = useQuery(
    ["/purchase/detail/excel", purchasesDetail],
    () => getPurchasesDetailExcel(purchasesDetail),
    {
      enabled: false,
      onSuccess: (data) => {
        const content = data?.content;
        const list = content.map((d: PurchaseDetailData) => ({
          가맹점ID: d.mallId,
          가맹점명: d.mallName,
          "사업자 등록증 상호명": d.businessName,
          "거래 일자": d.createdAt,
          "매입 일자": d.receiveDate,
          "입금 예정 일자": d.paymentFromCardCompany,
          "승인 번호": d.approvalNum,
          카드사: d.cardCompany,
          "거래 상태": d.status,
          "거래 금액": d.receivePrice,
          "카드사 수수료": d.cardFee,
          "입금 예정 금액": d.purchasePrice,
          "할부 기간": d.quota,
          "거래고유 KEY": d.paymentKey
        }));
        const totalList = {
          가맹점ID: "합계",
          가맹점명: "",
          "거래 일자": totalData.datePeriod,
          "매입 일자": "",
          "입금 예정 일자": "",
          "승인 번호": "",
          카드사: "",
          "거래 상태": "",
          "거래 금액": `${addCommas(totalData.receiveTotal)}`,
          "카드사 수수료": "",
          "입금 예정 금액": "",
          "할부 기간": "",
          "거래고유 KEY": ""
        };
        const combinedList = [...list, totalList];
        excelDownload({
          list: combinedList,
          sheetName:
            purchasesDetail.mallName === ""
              ? "승인·취소 내역 거래 상세 조회"
              : `승인·취소 내역 거래 상세 조회_${purchasesDetail.mallName}`,
          fileName:
            purchasesDetail.mallName === ""
              ? "승인·취소 내역 거래 상세 조회.xlsx"
              : `승인·취소 내역 거래 상세 조회_${purchasesDetail.mallName}.xlsx`
        });
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "success",
          message: "다운로드를 시작합니다."
        });
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  // ===================================================================================================================
  // 매입 상세 조회 - 엑셀 다운로드
  // ===================================================================================================================
  const handlePurchasesDetailExcel = async () => {
    if (!data?.content || data?.content?.length === 0) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "데이터가 없습니다."
      });
    } else {
      await refetch();
    }
  };

  // ===================================================================================================================
  // 페이징 처리
  // ===================================================================================================================
  // 총 페이지 수 산출
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;
  // 페이지 변경시 조회 처리
  const handlePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPurchasesDetail((prev) => ({ ...prev, page: page - 1 }));
  };

  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"end"}
        mb={1}
      >
        <Typography sx={{ fontWeight: "bold" }}>승인·취소 상세 조회</Typography>
        <LoadingButton
          icon={<SaveIcon />}
          size="medium"
          fullWidth={false}
          color="secondary"
          variant="outlined"
          loading={false}
          handleClick={handlePurchasesDetailExcel}
        >
          EXCEL 다운로드
        </LoadingButton>
      </Stack>

      <Paper
        sx={{
          border: "1px solid #F2F3F5",
          borderRadius: 3,
          overflow: "hidden"
        }}
      >
        <MuiTable
          rows={data?.content}
          columns={columns}
          id="id"
          redlineKey="status"
          redlineValue={["취소", "부분취소"]}
          totalData={false}
          sortingTarget={"createdAt"}
          sortDirection={purchasesDetail.sort}
        />
        
        <Box my={2}>
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            page={purchasesDetail.page + 1}
            onChange={handlePage}
            sx={{ display: "flex", justifyContent: "center" }}
          />
        </Box>
      </Paper>
    </Box>
  );
};
export default PurchaseDetail;
