import CloseIcon from "@mui/icons-material/Close";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import PlaylistAddCheckRoundedIcon from "@mui/icons-material/PlaylistAddCheckRounded";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { getMerchantApiKey } from "../../api/ApiKey";
import { GetApiKey } from "../../api/ApiKey/types";
import AllowedUrlModal from "../../components/ApiKey/AllowedDomainModal";
import ClientLayout from "../../components/Layout/Client/Layout";
import LoadingButton from "../../components/LoadingButton";
import Title from "../../components/Title";
import useRenewal from "../../hooks/useRenewal";
import isModalOpenAtom from "../../recoil/isModalOpen";
import AllowedDomainModal from "../../components/ApiKey/AllowedDomainModal";

interface FormInputs {
  mallName: string;
}

const initialFormValue = {
  mallName: ""
};

interface ApiKeyColumnHeadCell {
  id: keyof GetApiKey;
  label: string;
}

const columns: ApiKeyColumnHeadCell[] = [
  {
    id: "mallId",
    label: "가맹점 ID"
  },
  {
    id: "mallName",
    label: "가맹점명"
  },
  {
    id: "registrationFeeType",
    label: "API 키 타입"
  },
  {
    id: "allowedDomain",
    label: "허용된 도메인"
  },
  {
    id: "publicKey",
    label: "공개 키"
  },
  {
    id: "secretKey",
    label: "비밀 키"
  }
];

const ApiKey = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [queryData, setQueryData] = useState({
    page: 0,
    pageSize: 10,
    mallName: ""
  });

  const [selectedRow, setSelectedRow] = useState({
    isModalOpen: false,
    merchantId: "",
    merchantAllowedUrlId: ""
  });

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const { register, reset, handleSubmit } = useForm<FormInputs>({
    defaultValues: initialFormValue
  });
  const handleReset = () => {
    reset(initialFormValue);
  };
  const handleForm = (data: FormInputs) => {
    if (isLoading) return;
    setQueryData((prevValue) => ({
      ...prevValue,
      ...data,
      page: 0
    }));
  };

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 가맹점 key 정보 조회
  const { data, isLoading } = useQuery(
    ["/merchant/key", queryData],
    () => getMerchantApiKey(queryData),
    {
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      },
      onSuccess: () => {
        if (!isRenewalTokenLoading) {
          mutateRenewalToken();
        }
      }
    }
  );

  // ===================================================================================================================
  // 모달창 열기, 닫기
  // ===================================================================================================================
  const closeModal = () => {
    setSelectedRow({ ...selectedRow, isModalOpen: false });
  };

  const selectRow = (merchantId: string, merchantAllowedUrlId: string) => {
    setSelectedRow({ merchantId, merchantAllowedUrlId, isModalOpen: true });
  };

  // ===================================================================================================================
  // 페이지네이션
  // ===================================================================================================================
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;
  const handlePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setQueryData((prevValue) => ({ ...prevValue, page: page - 1 }));
  };

  // ===================================================================================================================
  // 키 복사
  // ===================================================================================================================
  const copyKey = async (
    key: string,
    keyType: "publicKey" | "secretKey",
    merchantAllowedUrlId: string
  ) => {
    if (merchantAllowedUrlId === null) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "등록된 URL이 없습니다. URL을 먼저 등록해주세요."
      });
      return;
    }
    try {
      await navigator.clipboard.writeText(key);
      const keyName = keyType === "publicKey" ? "공개 키" : "비밀 키";
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: `${keyName} ${key} 가 복사 되었습니다.`
      });
    } catch (error) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "복사에 실패했습니다."
      });
    }
  };

  return (
    <>
      <Title title="키 관리" />
      <ClientLayout>
        <Paper
          sx={{
            p: 3,
            border: "1px solid #F2F3F5",
            borderRadius: 3,
            mb: 3
          }}
        >
          <form onSubmit={handleSubmit(handleForm)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  id="input-mallName"
                  label="가맹점명"
                  fullWidth
                  size="small"
                  {...register("mallName")}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <LoadingButton
                    icon={<SearchIcon />}
                    size="medium"
                    fullWidth={false}
                    loading={false}
                  >
                    조회
                  </LoadingButton>
                  <Button
                    variant="outlined"
                    endIcon={<RestartAltIcon />}
                    onClick={handleReset}
                  >
                    조건 초기화
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Paper>

        <Stack direction={"row"} sx={{ mb: 1 }} alignItems={"center"} gap={2}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            API 키 목록
          </Typography>
          <Typography
            variant="body2"
            color={"error"}
            sx={{ display: "inline-flex", alignItems: "center" }}
          >
            * 도메인 등록을 위해
            <PlaylistAddCheckRoundedIcon sx={{ mx: 0.5 }} />
            아이콘을 클릭해 주세요.
          </Typography>
        </Stack>

        <Paper
          sx={{
            border: "1px solid #F2F3F5",
            borderRadius: 3,
            overflow: "hidden"
          }}
        >
          <TableContainer sx={{ maxHeight: 550, minHeight: 550 }}>
            <Table
              stickyHeader
              sx={{
                ...((!data || data?.content?.length === 0) && {
                  height: 550
                })
              }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      variant="head"
                      key={column.id}
                      sx={{
                        backgroundColor: "#FBFBFB",
                        whiteSpace: "nowrap",
                        py: 1.5
                      }}
                      align="center"
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!data || data?.content?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Typography align="center">데이터가 없습니다.</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {data?.content?.map((row, rowIndex) => (
                      <TableRow
                        key={`${row.mallId}-${row.registrationFeeType}-${rowIndex}`}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align="center"
                              sx={{ py: 0.5 }}
                            >
                              {column.id === "publicKey" ||
                              column.id === "secretKey" ? (
                                <>
                                  {value ? (
                                    <IconButton
                                      onClick={() =>
                                        copyKey(
                                          value,
                                          column.id as
                                            | "publicKey"
                                            | "secretKey",
                                          row.merchantAllowedDomainId
                                        )
                                      }
                                    >
                                      <ContentCopyRoundedIcon />
                                    </IconButton>
                                  ) : (
                                    <IconButton disabled>
                                      <CloseIcon />
                                    </IconButton>
                                  )}
                                </>
                              ) : (
                                value
                              )}
                              {column.id === "allowedDomain" && (
                                <IconButton
                                  onClick={() =>
                                    selectRow(
                                      row.merchantId,
                                      row.merchantAllowedDomainId
                                    )
                                  }
                                >
                                  <PlaylistAddCheckRoundedIcon />
                                </IconButton>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box my={2}>
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              page={queryData.page + 1}
              onChange={handlePage}
              sx={{ display: "flex", justifyContent: "center" }}
            />
          </Box>

          <AllowedDomainModal
            isOpen={selectedRow.isModalOpen}
            onClose={closeModal}
            merchantId={selectedRow.merchantId}
            merchantAllowedDomainId={selectedRow.merchantAllowedUrlId}
          />
        </Paper>
      </ClientLayout>
    </>
  );
};

export default ApiKey;
