import { instanceWithAuth } from "../Instance";
import { CommonApi } from "../type";
import {
  CardCompanies,
  CardCompanyByNameData,
  CardCompanyByTypeData,
  CardCompanyByTypeParams,
  CardCompanyGradeParams,
  CardCompanyInfo,
  CardCompanyInfoParams,
  Installments,
  ModifyCardCompanyGradeParams,
  postCardCompanyInstallmentsParams,
  SaveCardCompanyInfoParams
} from "./types";

/**
 *  카드사 목록 조회
 */
export const getCardCompanies = async () => {
  const { data } = await instanceWithAuth.get<CommonApi<CardCompanies>>(
    "/cardCompanies"
  );
  return data;
};

/**
 *  카드사 이름으로 카드사 정보 조회
 */
export const getCardCompanyByName = async (cardName: string) => {
  const { data } = await instanceWithAuth.get<CommonApi<CardCompanyByNameData>>(
    "/card-companies",
    { params: { cardName } }
  );
  return data;
};

/**
 *  카드사 업종, 등급별 정보 조회
 */
export const getCardCompanyByNameAndType = async (
  params: CardCompanyByTypeParams
) => {
  const { data } = await instanceWithAuth.get<
    CommonApi<CardCompanyByTypeData[]>
  >("/card-companies/grades", { params });
  return data;
};

/**
 *  카드사 정보 조회
 */
export const getCardCompanyInfo = async (params: CardCompanyInfoParams) => {
  const { data } = await instanceWithAuth.get<CommonApi<CardCompanyInfo>>(
    "/cardCompany",
    { params }
  );
  return data;
};

/**
 *  등급별 정보 추가
 */
export const postCardCompanyGrade = async (params: CardCompanyGradeParams) => {
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(
    "/card-companies/grades",
    params
  );
  return data;
};

/**
 *  등급 정보 수정
 */
export const putCardCompanyGrade = async (
  params: ModifyCardCompanyGradeParams
) => {
  const { data } = await instanceWithAuth.put<CommonApi<boolean>>(
    "/card-companies/grades",
    params
  );
  return data;
};

// ---------------------------------------------------------------------------------------------------------------------
/**
 *  카드사 정보 저장, 수정
 */
export const saveCardCompanyInfo = async (
  params: SaveCardCompanyInfoParams
) => {
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(
    "/cardCompany",
    params
  );
  return data;
};

/**
 *  카드사 할부 정보 조회
 */
export const getCardInstallments = async (
  cardCompanyName: string,
  mccType: string
) => {
  const { data } = await instanceWithAuth.get<CommonApi<Installments>>(
    "/cardCompany/installments",
    {
      params: { cardCompanyName, mccType }
    }
  );
  return data;
};

/**
 *  카드사 할부정보 수정
 */
export const saveCardInstallments = async (cardInstallmentData: any) => {
  const { data } = await instanceWithAuth.put(
    "/card-companies/installments",
    cardInstallmentData
  );
  return data;
};

/**
 *  카드사 할부 정보 추가 - 최초 할부 정보 등록
 */
export const postCardCompanyInstallments = async (
  cardInstallmentReservationData: postCardCompanyInstallmentsParams
) => {
  const { data } = await instanceWithAuth.post(
    "/card-companies/installments",
    cardInstallmentReservationData
  );
  return data;
};

export const saveCardInstallmentsReservation = async (
  cardInstallmentReservationData: any
) => {
  const { data } = await instanceWithAuth.post(
    "/cardCompany/installments/reservation",
    cardInstallmentReservationData
  );
  return data;
};
