import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useQuery } from "@tanstack/react-query";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  getPurchasesCardDetail,
  getPurchasesCardDetailExcel
} from "../../api/v2/Purchases";
import excelDownload from "../../libs/excel-download";
import isModalOpenAtom from "../../recoil/isModalOpen";
import { PurchasesDetailState } from "../../recoil/purchases/atom";
import LoadingButton from "../LoadingButton";
import { addCommas } from "../../libs/thousands-commas";

const columns = [
  {
    id: "cardCompany",
    label: "카드"
  },
  {
    id: "createdAt",
    label: "거래일자"
  },
  {
    id: "totalPayment",
    label: "거래합계 (건수)"
  },
  {
    id: "totalApproval",
    label: "승인합계 (건수)"
  },
  {
    id: "totalCancel",
    label: "취소합계 (건수)"
  },
  {
    id: "cardConfirmFee",
    label: "카드사 승인 수수료"
  },
  {
    id: "cardCancelFee",
    label: "카드사 취소 수수료"
  },
  {
    id: "purchasePrice",
    label: "입금예정금액"
  }
];

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  cardCompany: string;
}

const CardsDetailModal = ({ isOpen, setIsOpen, cardCompany }: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);
  const [cardsDetail, setCardsDetail] = useRecoilState(PurchasesDetailState);

  // ===================================================================================================================
  // 리액트 쿼리 - 카드사별 상세 조회
  // ===================================================================================================================
  // 카드사별 상세 조회
  const { isLoading, data: cardDetailData } = useQuery(
    ["/purchase/card/detail", cardsDetail, cardCompany],
    () => getPurchasesCardDetail({ ...cardsDetail, cardCompany: cardCompany }),
    { enabled: !!cardCompany } //cardCompany가 없으면 조회 안됨
  );

  // 카드사별 상세 조회 - 엑셀
  const { refetch } = useQuery(
    ["/purchase/card/detail/excel", cardsDetail, cardCompany],
    () =>
      getPurchasesCardDetailExcel({ ...cardsDetail, cardCompany: cardCompany }),
    {
      enabled: false,
      onSuccess: (data) => {
        const content = data?.content;
        if (!content || content.length === 0) {
          setIsModalOpen({
            value: true,
            position: "top",
            alertSeverity: "error",
            message: "데이터가 없습니다."
          });
        } else {
          const list = content?.map((d) => ({
            카드: d.cardCompanyName,
            매입일자: d.datePeriod,
            거래합계: d.receivePrice,
            "거래합계 건수": d.receiveCount,
            승인합계: d.confirmPrice,
            "승인합계 건수": d.confirmCount,
            취소합계: d.cancelPrice,
            "취소합계 건수": d.cancelCount,
            "카드사 승인 수수료": d.cardConfirmFee,
            "카드사 취소 수수료": d.cardCancelFee,
            입금예정금액: d.purchasePrice
          }));
          excelDownload({
            list,
            sheetName: "승인·취소내역 카드사별 합계 상세 내역",
            fileName: "승인·취소내역 카드사별 합계 상세 내역.xlsx"
          });
          setIsModalOpen({
            value: true,
            position: "top",
            alertSeverity: "success",
            message: "다운로드를 시작합니다."
          });
        }
      },
      onError: (error) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: "데이터를 다운로드하는 동안 오류가 발생했습니다."
        });
      }
    }
  );

  // ===================================================================================================================
  // 엑셀 다운로드
  // ===================================================================================================================
  // 거래 상세 내역 - 엑셀 다운로드
  const handleCardDetailExcel = async () => {
    await refetch();
  };

  // ===================================================================================================================
  // 페이지 네이션
  // ===================================================================================================================
  const totalPages = cardDetailData?.pageable?.totalPages
    ? cardDetailData?.pageable?.totalPages
    : 0;
  const handlePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setCardsDetail((prevValue: any) => ({ ...prevValue, page: page - 1 }));
  };

  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth={"lg"}>
        <DialogTitle
          sx={{ boxShadow: "0px 5px 14px -1px rgba(204, 204, 204, 0.5)" }}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          상세 카드 정보
          <Stack
            direction="row"
            justifyContent="right"
            alignItems="center"
            gap={3}
          >
            <LoadingButton
              icon={<SaveIcon />}
              size="medium"
              fullWidth={false}
              color="secondary"
              variant="outlined"
              loading={false}
              handleClick={handleCardDetailExcel}
            >
              EXCEL 다운로드
            </LoadingButton>
            <IconButton onClick={() => setIsOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <TableContainer
            sx={{ border: "1px solid #E4E4E7", borderRadius: 2, mt: 3 }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      sx={{ backgroundColor: "#FBFBFB" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {cardDetailData?.content && !isLoading ? (
                  cardDetailData?.content.map((c, index: number) => (
                    <TableRow key={index}>
                      <TableCell align="center">{c.cardCompanyName}</TableCell>
                      <TableCell align="center">{c.datePeriod}</TableCell>
                      <TableCell align="center">
                        {addCommas(c.receivePrice)} ({c.receiveCount})
                      </TableCell>
                      <TableCell align="center">
                        {addCommas(c.confirmPrice)} ({c.confirmCount})
                      </TableCell>
                      <TableCell align="center">
                        {addCommas(c.cancelPrice)} ({c.cancelCount})
                      </TableCell>
                      <TableCell align="center">
                        {addCommas(c.cardConfirmFee)}
                      </TableCell>
                      <TableCell align="center">
                        {addCommas(c.cardCancelFee)}
                      </TableCell>
                      <TableCell align="center">
                        {addCommas(c.purchasePrice)}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell key={index}>
                        <Skeleton variant="rounded" animation="wave" />
                      </TableCell>
                    ))}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={2}>
            <Pagination
              count={totalPages}
              page={cardsDetail.page + 1}
              onChange={handlePage}
              variant="outlined"
              shape="rounded"
              sx={{ display: "flex", justifyContent: "center" }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CardsDetailModal;
