import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { sendPaymentReceipt } from "../../api/v2/Payments";
import isModalOpenAtom from "../../recoil/isModalOpen";
import { HelperTextMessage } from "../Merchants/TerminateContractModal";

interface PaymentViewModalProps {
  isOpen: boolean;
  onClose: () => void;
  paymentKeys: any;
}

const SendReceiptModal = ({
  isOpen,
  onClose,
  paymentKeys
}: PaymentViewModalProps) => {
  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [isModalOpen, setIsModalOpen] = useRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: ""
    }
  });

  // 모달이 닫힐 때 필드 초기화
  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 거래 내역 영수증 전송
  const { mutate, isLoading } = useMutation(sendPaymentReceipt, {
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "이메일 전송이 완료되었습니다."
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // ===================================================================================================================
  // 이메일 전송
  // ===================================================================================================================
  const onSubmit = (formData: any) => {
    if (isLoading) return;
    const params = {
      paymentKeys,
      email: formData.email
    };
    mutate(params);
    onClose();
  };

  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth={"xs"}>
        <DialogTitle
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          영수증 발송
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack pt={3} gap={3}>
              <TextField
                id="email"
                placeholder="이메일을 입력해주세요."
                {...register("email", {
                  required: "이메일은 필수값 입니다.",
                  pattern: {
                    value:
                      /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                    message: "형식에 맞지 않는 이메일 주소입니다."
                  }
                })}
                helperText={
                  <HelperTextMessage>{errors.email?.message}</HelperTextMessage>
                }
                error={!!errors.email}
              />
              <Button type="submit" variant="contained" sx={{ color: "white" }}>
                영수증 발송
              </Button>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default SendReceiptModal;
