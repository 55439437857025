import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import { IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../..";
import { postAllowedDomains, putAllowedDomains } from "../../api/ApiKey";
import isModalOpenAtom from "../../recoil/isModalOpen";
import { HelperTextMessage } from "../Merchants/TerminateContractModal";

interface Props {
  merchantId: string;
  domainData?: string[];
  merchantAllowedDomainId?: string;
  onClose: () => void;
}

interface FormValues {
  domain: string;
}

const CreateDomainForm = ({
  merchantId,
  domainData,
  merchantAllowedDomainId,
  onClose
}: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 컴포넌트 스테이트
  // ===================================================================================================================
  const [domainList, setDomainList] = useState<string[]>(
    domainData ? [...domainData] : []
  );

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 가맹점 허용 도메인 등록
  const { mutate } = useMutation(postAllowedDomains, {
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "도메인 등록을 완료했습니다."
      });
      queryClient.invalidateQueries(["/merchant/key"]);
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // 가맹점 허용 URL 수정
  const { mutate: updateMutate } = useMutation(putAllowedDomains, {
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "도메인 수정을 완료했습니다."
      });
      queryClient.invalidateQueries(["/merchant/key"]);
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<FormValues>({
    defaultValues: {
      domain: ""
    }
  });

  // ===================================================================================================================
  // 핸들러 함수
  // ===================================================================================================================
  // 호스트 추가
  const onSubmit = (data: FormValues) => {
    if (domainList?.includes(data.domain)) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "이미 등록된 도메인 입니다."
      });
      reset();
      return;
    }
    if (domainList.length >= 10) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "도메인은 10개 이하로 입력하세요."
      });
      return reset();
    } else {
      setDomainList((prevList) => [...prevList, data.domain]);
      reset();
    }
  };

  // 호스트 삭제
  const handleDelete = (index: number) => {
    setDomainList((prevList) => prevList.filter((_, i) => i !== index));
  };

  // 실제 호스트 등록
  const handleRegister = () => {
    const params = {
      merchantId,
      domains: domainList
    };
    mutate(params);
    onClose();
  };

  // 호스트 수정
  const handleUpdateDomain = () => {
    const params = {
      merchantId,
      domains: domainList,
      merchantAllowedDomainId: merchantAllowedDomainId || ""
    };
    updateMutate(params);
    onClose();
  };

  return (
    <>
      <Box pt={1}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack direction={"row"} gap={2} sx={{ mb: 2 }}>
            <TextField
              label="URL"
              variant="outlined"
              fullWidth
              required
              size="small"
              {...register("domain", {
                required: "URL을 입력해주세요."
              })}
              error={!!errors.domain}
              helperText={
                <HelperTextMessage>{errors.domain?.message}</HelperTextMessage>
              }
            />
            <Button variant="outlined" type="submit" sx={{ height: 40 }}>
              추가
            </Button>
          </Stack>

          <Stack sx={{ mb: 2, px: 1 }}>
            {domainList.map((domain, index) => (
              <Stack
                key={index}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography>{domain}</Typography>
                <IconButton
                  onClick={() => handleDelete(index)}
                  sx={{ strokeWidth: 0.5 }}
                >
                  <RemoveCircleOutlineRoundedIcon sx={{ strokeWidth: 0.5 }} />
                </IconButton>
              </Stack>
            ))}
          </Stack>
          <Button
            variant="contained"
            fullWidth
            sx={{ color: "white" }}
            onClick={
              merchantAllowedDomainId ? handleUpdateDomain : handleRegister
            }
            disabled={domainList.length < 1 || domainList.length > 10}
          >
            {merchantAllowedDomainId ? "수정 완료" : "등록 완료"}
          </Button>
        </form>
      </Box>
    </>
  );
};

export default CreateDomainForm;
