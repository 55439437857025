import ErrorOutline from "@mui/icons-material/ErrorOutline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { postAdminMerchant } from "../../api/AdminRegistUser";
import {
  getBankList,
  getBusinessNumber,
  merchantFileUpload
} from "../../api/MerchantRegistration";
import { Bank, StepData } from "../../api/MerchantRegistration/types";
import { FileUploadForm } from "../../libs/file-upload";
import { engValidate, numberValidate } from "../../libs/input-validation";
import { HelperTextMessage } from "../../pages/Client/MerchantRegistration";
import isModalOpenAtom from "../../recoil/isModalOpen";
import { CountryList } from "../CountryList";
import { MerchantFormData } from "../MerchantRegistration/MerchantInformations";
import PostCode from "../PostCode";
import { useNavigate } from "react-router-dom";
import { uploadFiles } from "../../libs/file-mapping";
import Loading from "../Loadings/Loading";

interface Props {
  closeModal: () => void;
  detailData: StepData | null;
  userId: number;
}

const AdminRegistMerechantForm = ({
  closeModal,
  detailData,
  userId
}: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  // 사업자 정보 주소 스테이트
  const [openPostCode, setOpenPostCode] = useState({
    isOpen: false,
    referenceIndex: 0
  });
  // 대표자 주소 스테이트
  const [openOwnerPostCode, setOpenOwnerPostCode] = useState({
    isOpen: false,
    referenceIndex: 0
  });

  // 주소 체크박스 스테이트
  const [isCheckedAddress, setIsCheckedAddress] = useState(
    detailData?.isCheckedAddress ?? false
  );

  // 업종 스테이트
  const [businessList, setBusinessList] = useState<string[]>([]);
  // 업태 스테이트
  const [businessStatus, setBusinessStatus] = useState<string[]>([]);

  // 체크박스 스테이트
  const [isCheckedTestId, setIsCheckedTestId] = useState(
    detailData?.isCheckedTestId ?? false
  );

  // 은행 정보 스테이트
  const [selectedBank, setSelectedBank] = useState<Bank>({
    code: "",
    name: ""
  });

  // 담당자 정보 체크박스 스테이트
  const [isChecked, setIsChecked] = useState(detailData?.isChecked ?? false);
  // 세금 계산 담당자 체크박스 스테이트
  const [isCheckedTax, setIsCheckedTax] = useState(
    detailData?.isCheckedTax ?? false
  );

  // 파일 데이터 스테이트
  const [fileData, setFileData] = useState<FileUploadForm>({
    // 개인 사업자
    businessLicense: undefined,
    bankBookCopy: undefined,
    sealCertificate: undefined,
    사용인감계: undefined,
    idCardFront: undefined,
    idCardBack: undefined,
    gradeConfirmation: undefined,
    // 법인사업자
    corporateRegistration: undefined,
    actualOwnerConfirmation: undefined,
    corporateSealCertificate: undefined
  });

  // 업종, 업태 ref
  const businessCategoryRef = useRef<HTMLInputElement | null>(null);
  const businessConditionsRef = useRef<HTMLInputElement | null>(null);

  // 업태, 업종값 스테이트
  const [conditionValue, setConditionValue] = useState("");
  const [categoryValue, setCategoryValue] = useState("");

  // 네비게이션
  const navigate = useNavigate();

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 파일 업로드
  // `mutateAsync`는 Promise를 반환하는 비동기 함수다. `await`를 사용해 작업이 완료될 때 까지 기다릴 수 있다.
  const { mutateAsync: fileMutate, isLoading: fileLoading } = useMutation(
    merchantFileUpload,
    {
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error.message
        });
      }
    }
  );

  // 가맹점 등록
  const { mutate: registMutate, isLoading } = useMutation(postAdminMerchant, {
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "가맹등록이 완료 되었습니다."
      });
      closeModal();
      navigate("/admin/merchants");
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error.response.data.message
      });
    }
  });

  // 은행 리스트 가져오기
  const { data: bankNameList } = useQuery(["getBankList"], () => getBankList());

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    setFocus,
    clearErrors,
    setError,
    formState: { errors }
  } = useForm<MerchantFormData>({
    defaultValues: {
      // 사업자 정보
      mallName: detailData?.mallName ?? "",
      mallNameEn: detailData?.mallNameEn ?? "",
      businessType: detailData?.merchantInformation?.businessType ?? "개인",
      businessName: detailData?.merchantInformation?.businessName ?? "",
      registrationNumber:
        detailData?.merchantInformation?.registrationNumber ?? "",
      corporationNumber:
        detailData?.merchantInformation?.corporationNumber ?? "",
      postCode: detailData?.merchantInformation?.postCode ?? "",
      mallAddress: detailData?.merchantInformation?.mallAddress ?? "",
      detailAddress: detailData?.merchantInformation?.detailAddress ?? "",
      headOfficePostCode:
        detailData?.merchantInformation?.headOfficePostCode ?? "",
      headOfficeAddress:
        detailData?.merchantInformation?.headOfficeAddress ?? "",
      headOfficeDetailAddress:
        detailData?.merchantInformation?.headOfficeDetailAddress ?? "",
      phone: detailData?.merchantInformation?.phone ?? "",
      fax: detailData?.merchantInformation?.fax ?? "",
      mailOrderSalesNumber:
        detailData?.merchantInformation?.mailOrderSalesNumber ?? "",
      mallUrl: detailData?.mallUrl ?? "",
      mallIp: detailData?.mallIp ?? "",
      introductionCompany:
        detailData?.merchantInformation?.introductionCompany ?? "",
      testId: detailData?.merchantInformation?.testId ?? "",
      testPassword: detailData?.merchantInformation?.testPassword ?? "",
      merchantCategory:
        detailData?.merchantInformation?.merchantCategory
          ?.split(" | ")
          .map((name) => ({ name })) ?? [],
      businessConditions:
        detailData?.merchantInformation?.businessConditions
          ?.split(" | ")
          .map((name) => ({ name })) ?? [],

      // 대표자 정보
      merchantOwnerInformation:
        detailData?.merchantOwnerInformation &&
        detailData?.merchantOwnerInformation.length > 0
          ? detailData?.merchantOwnerInformation
          : [
              {
                name: "",
                birth: "",
                nationality: "대한민국",
                postCode: "",
                address: "",
                detailAddress: "",
                phone: ""
              }
            ],

      // 은행 정보
      bankName: detailData?.merchantBankInformation?.bankName ?? "",
      accountCode: detailData?.merchantBankInformation?.accountCode ?? "",
      accountHolder: detailData?.merchantBankInformation?.accountHolder ?? "",

      // 담당자 정보
      merchantInChargeInformation: detailData?.merchantInChargeInformation,

      // 파일 정보 - 개인사업자
      businessLicense: "",
      bankBookCopy: "",
      sealCertificate: "",
      idCardFront: "",
      idCardBack: "",
      gradeConfirmation: "",

      // 파일 정보 - 법인사업자
      corporateRegistration: "",
      actualOwnerConfirmation: "",
      corporateSealCertificate: "",
      사용인감계: ""
    },
    mode: "onBlur"
  });

  // ===================================================================================================================
  // 대표자 양식 추가, 삭제
  // ===================================================================================================================
  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: "merchantOwnerInformation"
  });

  // ===================================================================================================================
  // 업종, 업태 추가 삭제 - useFieldArray
  // ===================================================================================================================
  // 업태 useFieldArray
  const {
    fields: businessConditionsFields,
    append: appendBusinessConditions,
    remove: removeBusinessConditions
  } = useFieldArray<MerchantFormData>({
    control,
    name: "businessConditions"
  });

  // 업종 useFieldArray
  const {
    fields: merchantCategoryFields,
    append: appendMerchantCategory,
    remove: removeMerchantCategory
  } = useFieldArray<MerchantFormData>({
    control,
    name: "merchantCategory"
  });

  // 개인, 법인 구분
  const currentBusinessType = detailData?.merchantInformation?.businessType;

  // ===================================================================================================================
  // 사업자 번호 검증
  // ===================================================================================================================
  // 사업자 번호 검증
  const { refetch } = useQuery(
    [`/bypass/business-number/validate`, watch("registrationNumber")],
    () => getBusinessNumber(watch("registrationNumber")),
    {
      enabled: false,
      retry: false,
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
        setFocus("registrationNumber");
      }
    }
  );

  // ===================================================================================================================
  // 가맹점 등록
  // ===================================================================================================================
  const onSubmit = async (formData: MerchantFormData) => {
    if (isLoading) return;

    // 사업자 등록 번호 확인
    const { isError } = await refetch();
    if (isError) return;

    // 업태 및 업종 검증
    if (businessConditionsFields.length === 0) {
      setError("businessConditions", {
        type: "manual",
        message: "업태는 최소 1개 이상이어야 합니다."
      });
      businessConditionsRef.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (merchantCategoryFields.length === 0) {
      setError("merchantCategory", {
        type: "manual",
        message: "업종은 최소 1개 이상이어야 합니다."
      });
      businessCategoryRef.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    // 대표자 정보 묶어서 보내기
    const formMerchantOwnerInfo =
      formData.merchantOwnerInformation?.map((info) => {
        const fullAddress = `${info.postCode} | ${info.address} | ${info.detailAddress}`;
        const year = info?.birth?.substring(0, 4);
        const month = info?.birth?.substring(4, 6);
        const day = info?.birth?.substring(6, 8);
        const birthString = `${year}-${month}-${day}`;
        return {
          ...info,
          address: fullAddress,
          birth: birthString
        };
      }) ?? [];

    let newHeadOfficeAddress: string | undefined;
    if (formData.headOfficePostCode) {
      newHeadOfficeAddress = `${formData.headOfficePostCode} | ${formData.headOfficeAddress} | ${formData.headOfficeDetailAddress}`;
    } else {
      newHeadOfficeAddress = undefined;
    }

    const merchantFileDto = await uploadFiles(
      fileData,
      formData.businessType,
      fileMutate
    );

    // 폼 데이터
    const newFormData = {
      registrant: "ADMIN",
      userId: userId,
      mallIp: formData.mallIp,
      mallName: formData.mallName,
      mallNameEn: formData.mallNameEn,
      mallUrl: formData.mallUrl,
      merchantInformation: {
        address: `${formData.postCode} | ${formData.mallAddress} | ${formData.detailAddress}`,
        headOfficeAddress: newHeadOfficeAddress,
        merchantCategory: merchantCategoryFields
          .map((condition) => condition.name ?? "")
          .filter(Boolean)
          .join(" | "),
        businessConditions: businessConditionsFields
          .map((condition) => condition.name ?? "")
          .filter(Boolean)
          .join(" | "),
        businessType: formData.businessType,
        businessName: formData.businessName,
        corporationNumber: formData.corporationNumber,
        fax: formData.fax,
        introductionCompany: formData.introductionCompany,
        mailOrderSalesNumber: formData.mailOrderSalesNumber,
        phone: formData.phone,
        registrationNumber: formData.registrationNumber,
        testId: formData.testId,
        testPassword: formData.testPassword
      },
      merchantBankInformation: {
        accountCode: formData.accountCode,
        accountHolder: formData.accountHolder,
        bankCode: selectedBank.code,
        bankName: selectedBank.name
      },
      merchantInChargeInformation: [
        {
          email: formData.merchantInChargeInformation[0].email,
          name: formData.merchantInChargeInformation[0].name,
          phone: formData.merchantInChargeInformation[0].phone,
          role: "계약"
        },
        {
          email: formData.merchantInChargeInformation[1].email,
          name: formData.merchantInChargeInformation[1].name,
          phone: formData.merchantInChargeInformation[1].phone,
          role: "정산"
        },
        {
          email: formData.merchantInChargeInformation[2].email,
          name: formData.merchantInChargeInformation[2].name,
          phone: formData.merchantInChargeInformation[2].phone,
          role: "계산서"
        }
      ],
      merchantOwnerInformation: [...formMerchantOwnerInfo],
      merchantFileDto: merchantFileDto
    };

    registMutate(newFormData);
  };

  // ===================================================================================================================
  // 주소 검색 모달창
  // ===================================================================================================================
  // 가맹점 주소 검색 모달창 열기
  const openPostCodeModal = (index: number) => {
    setOpenPostCode({
      isOpen: true,
      referenceIndex: index
    });
  };
  // 대표자 주소 검색 모달창 열기
  const openOwnerPostCodeModal = (index: number) => {
    setOpenOwnerPostCode({
      isOpen: true,
      referenceIndex: index
    });
  };

  // 검색된 주소값 가맹점 주소 인풋에 담기
  const postCodeHandler = (data: any, index?: number) => {
    if (index === 0) {
      setValue("postCode", data.zonecode);
      setValue("mallAddress", data.address);
      clearErrors("postCode");
      clearErrors("mallAddress");
    } else {
      setValue("headOfficePostCode", data.zonecode);
      setValue("headOfficeAddress", data.address);
    }
  };

  // 검색된 주소값 대표자 주소 인풋에 담기
  const ownerAddressHandler = (data: any, index?: number) => {
    if (index === undefined) return;
    setValue(`merchantOwnerInformation.${index}.postCode`, data.zonecode);
    setValue(`merchantOwnerInformation.${index}.address`, data.address);
    clearErrors(`merchantOwnerInformation.${index}.postCode`);
    clearErrors(`merchantOwnerInformation.${index}.address`);
  };

  // ===================================================================================================================
  // 체크박스 내용 붙여넣기
  // ===================================================================================================================
  // 주소 체크박스
  const checkBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckedAddress(e.target.checked);
    if (e.target.checked) {
      const getPostCode = getValues("postCode");
      setValue("headOfficePostCode", getPostCode);
      const getAddress = getValues("mallAddress");
      setValue("headOfficeAddress", getAddress);
      const getDetailAddress = getValues("detailAddress");
      setValue("headOfficeDetailAddress", getDetailAddress);
    }
  };

  // 관리자 체크박스
  const managerCheckBox = (e: any) => {
    setIsChecked(e.target.checked);
    if (e.target.checked) {
      const getName = getValues(`merchantInChargeInformation.${0}.name`);
      setValue(`merchantInChargeInformation.${1}.name`, getName);
      clearErrors(`merchantInChargeInformation.${1}.name`);
      const getEmail = getValues(`merchantInChargeInformation.${0}.email`);
      setValue(`merchantInChargeInformation.${1}.email`, getEmail);
      clearErrors(`merchantInChargeInformation.${1}.email`);
      const getPhone = getValues(`merchantInChargeInformation.${0}.phone`);
      setValue(`merchantInChargeInformation.${1}.phone`, getPhone);
      clearErrors(`merchantInChargeInformation.${1}.phone`);
    }
  };

  // 세금 담당자 체크박스
  const taxCheckBox = (e: any) => {
    setIsCheckedTax(e.target.checked);
    if (e.target.checked) {
      const getName = getValues(`merchantInChargeInformation.${1}.name`);
      setValue(`merchantInChargeInformation.${2}.name`, getName);
      clearErrors(`merchantInChargeInformation.${2}.name`);
      const getEmail = getValues(`merchantInChargeInformation.${1}.email`);
      setValue(`merchantInChargeInformation.${2}.email`, getEmail);
      clearErrors(`merchantInChargeInformation.${2}.email`);
      const getPhone = getValues(`merchantInChargeInformation.${1}.phone`);
      setValue(`merchantInChargeInformation.${2}.phone`, getPhone);
      clearErrors(`merchantInChargeInformation.${2}.phone`);
    }
  };

  // ===================================================================================================================
  // 테스트용 ID, 비밀번호 체크박스
  // ===================================================================================================================
  const watchTestId = watch("testId");

  useEffect(() => {
    if (watchTestId !== "비회원 결제가능") {
      setIsCheckedTestId(false);
    } else {
      setIsCheckedTestId(true);
    }
  }, [watchTestId]);

  // 테스트용 아이디/비밀번호 체크박스 핸들러
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckedTestId(event.target.checked);
    if (event.target.checked) {
      setValue("testId", "비회원 결제가능");
      setValue("testPassword", "비회원 결제가능");
      clearErrors("testId");
      clearErrors("testPassword");
    } else {
      setValue("testId", "");
      setValue("testPassword", "");
    }
  };

  // ===================================================================================================================
  // 화면 불러 왔을 때
  // - stepData 에서 bankName 과 bankCode 를 가져온다
  // - 가져온 bankName, bankCode 를 bank 객체로 만든다
  // - bank 객체는 selectedBank state 에 넣는다
  // ===================================================================================================================
  useEffect(() => {
    if (!bankNameList || bankNameList.content.length < 1) return;
    const bankName = detailData?.merchantBankInformation?.bankName ?? "";
    const bankCode = detailData?.merchantBankInformation?.bankCode ?? "";

    setSelectedBank({
      name: bankName,
      code: bankCode
    });
  }, [detailData, bankNameList]);

  // ===================================================================================================================
  // 은행 선택
  // - 이벤트에서 밸류(뱅크네임)를 가져온다
  // - 뱅크네임으로 뱅크 리스트에서 뱅크 오브젝트를 찾는다
  // - 뱅크 오브젝트를 스테이트에 할당한다
  // ===================================================================================================================
  const bankSelectHandler = (event: any) => {
    const selectedBankName = event.target.value;

    if (!bankNameList) return;

    const selectedBank = bankNameList.content.find(
      (db) => db.name === selectedBankName
    );

    if (!selectedBank) return;
    setSelectedBank(selectedBank);
    setValue("bankName", selectedBank.name);
    clearErrors("bankName");
  };

  // ===================================================================================================================
  // 업종
  // ===================================================================================================================
  const handleAddMerchantCategory = () => {
    if (!categoryValue.trim()) return;
    if (merchantCategoryFields.length >= 5) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "업종은 최대 5개까지만 등록 가능합니다."
      });
      return;
    }
    appendMerchantCategory({ name: categoryValue.trim() });
    setCategoryValue("");
  };

  // 업태 삭제 핸들러
  const handleDeleteMerchantCategory = (index: number) => {
    removeMerchantCategory(index);
  };

  // ===================================================================================================================
  // 업태
  // ===================================================================================================================
  const handleAddBusinessCondition = () => {
    if (!conditionValue.trim()) return;
    if (businessConditionsFields.length >= 5) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "업태는 최대 5개까지만 등록 가능합니다."
      });
      return;
    }
    appendBusinessConditions({ name: conditionValue.trim() });
    setConditionValue("");
  };

  // 업태 삭제 핸들러
  const handleDeleteBusinessCondition = (index: number) => {
    removeBusinessConditions(index);
  };

  // ===================================================================================================================
  // 파일 확장자 검사
  // ===================================================================================================================
  // 파일 확장자 검사
  const checkFileExtension = (fileName: string) => {
    const fileExtension = fileName.split(".").pop()?.toLowerCase();
    return ["jpg", "jpeg", "gif", "png", "pdf"].includes(fileExtension ?? "");
  };

  const fileValidation = useCallback(() => {
    const fileFields: { name: keyof FileUploadForm; label: string }[] = [
      { name: "businessLicense", label: "사업자등록증" },
      { name: "bankBookCopy", label: "통장사본" },
      { name: "sealCertificate", label: "대표자 인감증명서" },
      { name: "사용인감계", label: "사용인감계" },
      { name: "corporateRegistration", label: "등기사항전부증명서" },
      { name: "idCardFront", label: "신분증 앞면" },
      { name: "idCardBack", label: "신분증 뒷면" },
      { name: "gradeConfirmation", label: "영중소 등급확인서" },
      { name: "actualOwnerConfirmation", label: "실제소유자확인서" },
      { name: "corporateSealCertificate", label: "법인인감증명서" }
    ];

    let isError = false;

    fileFields.forEach(({ name, label }) => {
      const file = fileData[name];
      if (file && !checkFileExtension(file.name)) {
        setError(name, {
          type: "required",
          message: `${label} 첨부 파일 종류는 jpg, jpeg, gif, png, pdf 입니다.`
        });
        isError = true;
      }
    });

    return isError;
  }, [fileData, setError]);

  const [buttonDisable, setButtonDisable] = useState(true);

  useEffect(() => {
    const isError = fileValidation();
    setButtonDisable(isError);
  }, [fileData, fileValidation]);

  // ===================================================================================================================
  // 파일 업로드
  // ===================================================================================================================
  const 파일선택체크 = (
    inputName:
      | "businessLicense"
      | "bankBookCopy"
      | "sealCertificate"
      | "corporateRegistration"
      | "idCardFront"
      | "idCardBack"
      | "actualOwnerConfirmation"
      | "corporateSealCertificate"
      | "gradeConfirmation"
      | "사용인감계"
  ) => {
    const selectedFileName = getValues(inputName);
    if (selectedFileName === "") {
      return false;
    } else {
      return true;
    }
  };
  const 파일선택텍스트 = (
    inputName:
      | "businessLicense"
      | "bankBookCopy"
      | "sealCertificate"
      | "corporateRegistration"
      | "idCardFront"
      | "idCardBack"
      | "actualOwnerConfirmation"
      | "corporateSealCertificate"
      | "gradeConfirmation"
      | "사용인감계"
  ) => {
    if (파일선택체크(inputName)) {
      return "파일 수정";
    } else {
      return "파일 선택";
    }
  };
  const 사업자등록증 = (e: any) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("businessLicense", name);
    setFileData((prev) => {
      return { ...prev, businessLicense: file };
    });
    if (errors.businessLicense) {
      clearErrors("businessLicense");
    }
  };
  const 통장사본 = (e: any) => {
    if (e.target.files < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("bankBookCopy", name);
    setFileData((prev) => {
      return { ...prev, bankBookCopy: file };
    });
    if (errors.bankBookCopy) {
      clearErrors("bankBookCopy");
    }
  };
  const 인감증명서 = (e: any) => {
    if (e.target.files < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("sealCertificate", name);
    setFileData((prev) => {
      return { ...prev, sealCertificate: file };
    });
    if (errors.sealCertificate) {
      clearErrors("sealCertificate");
    }
  };
  const 등기사항전부증명서 = (e: any) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("corporateRegistration", name);
    setFileData((prev) => {
      return { ...prev, corporateRegistration: file };
    });
    if (errors.corporateRegistration) {
      clearErrors("corporateRegistration");
    }
  };
  const 신분증앞 = (e: any) => {
    if (e.target.files < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("idCardFront", name);
    setFileData((prev) => {
      return { ...prev, idCardFront: file };
    });
    if (errors.idCardFront) {
      clearErrors("idCardFront");
    }
  };
  const 신분증뒤 = (e: any) => {
    if (e.target.files < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("idCardBack", name);
    setFileData((prev) => {
      return { ...prev, idCardBack: file };
    });
    if (errors.idCardBack) {
      clearErrors("idCardBack");
    }
  };
  const 실제소유자확인서 = (e: any) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("actualOwnerConfirmation", name);
    setFileData((prev) => {
      return { ...prev, actualOwnerConfirmation: file };
    });
    if (errors.actualOwnerConfirmation) {
      clearErrors("actualOwnerConfirmation");
    }
  };
  const 법인인감증명서 = (e: any) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("corporateSealCertificate", name);
    setFileData((prev) => {
      return { ...prev, corporateSealCertificate: file };
    });
    if (errors.corporateSealCertificate) {
      clearErrors("corporateSealCertificate");
    }
  };
  const 등급확인서 = (e: any) => {
    if (e.target.files < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("gradeConfirmation", name);
    setFileData((prev) => {
      return { ...prev, gradeConfirmation: file };
    });
    if (errors.gradeConfirmation) {
      clearErrors("gradeConfirmation");
    }
  };
  const 사용인감계 = (e: any) => {
    if (e.target.files < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("사용인감계", name);
    setFileData((prev) => {
      return { ...prev, 사용인감계: file };
    });
    if (errors.사용인감계) {
      clearErrors("사용인감계");
    }
  };

  return (
    <>
      {fileLoading && <Loading isLoading={fileLoading} />}
      {/* 사용자 등록 양식 */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={3}>
          {/* 사업자 등록 ======================================================================================= */}
          <Box
            sx={{
              border: "1px solid #E4E4E7",
              borderRadius: 2,
              overflow: "hidden"
            }}
          >
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems="center"
              sx={{
                py: 1,
                borderBottom: "1px solid #E4E4E7",
                backgroundColor: "#f7f7f7"
              }}
            >
              <Typography>
                사업자 정보 ({detailData?.merchantInformation?.businessType})
              </Typography>
            </Stack>
            <Grid container spacing={4} p={3}>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="mallName"
                  type="text"
                  label="가맹점명"
                  required
                  size="small"
                  fullWidth
                  {...register("mallName", {
                    required: "가맹점명은 필수값 입니다."
                  })}
                  helperText={
                    <HelperTextMessage>
                      {errors.mallName?.message}
                    </HelperTextMessage>
                  }
                  error={!!errors.mallName}
                  ref={register("mallName").ref}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  id="businessName"
                  label="사업자 등록증 상호명"
                  required
                  size="small"
                  {...register("businessName", {
                    required: "사업자 등록증 상호명은 필수값 입니다."
                  })}
                  fullWidth
                  helperText={
                    <HelperTextMessage>
                      {errors.businessName?.message}
                    </HelperTextMessage>
                  }
                  error={!!errors.businessName}
                  ref={register("businessName").ref}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="registrationNumber"
                  placeholder="등록번호 10자리를 입력해주세요."
                  label="사업자등록번호"
                  required
                  size="small"
                  {...register("registrationNumber", {
                    required: "사업자등록번호는 필수값 입니다.",
                    maxLength: {
                      value: 10,
                      message: "사업자등록번호는 10자리 입니다."
                    }
                  })}
                  fullWidth
                  onInput={numberValidate}
                  helperText={
                    <HelperTextMessage>
                      {errors.registrationNumber?.message}
                    </HelperTextMessage>
                  }
                  error={!!errors.registrationNumber}
                  ref={register("registrationNumber").ref}
                />
              </Grid>

              {currentBusinessType === "법인" ? (
                <>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="mallNameEn"
                      type="text"
                      label="사업장 영문 상호"
                      required
                      size="small"
                      {...register("mallNameEn", {
                        required: "사업장 영문 상호는 필수값 입니다."
                      })}
                      onInput={engValidate}
                      fullWidth
                      helperText={
                        <HelperTextMessage>
                          {errors.mallNameEn?.message}
                        </HelperTextMessage>
                      }
                      error={!!errors.mallNameEn}
                      ref={register("mallNameEn").ref}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      placeholder="등록번호 13자리를 입력해주세요."
                      label="법인등록번호"
                      required
                      size="small"
                      {...register("corporationNumber", {
                        required: "법인등록번호는 필수값 입니다.",
                        maxLength: {
                          value: 13,
                          message: "법인등록번호는 13자리 입니다."
                        }
                      })}
                      onInput={numberValidate}
                      fullWidth
                      helperText={
                        <HelperTextMessage>
                          {errors.corporationNumber?.message}
                        </HelperTextMessage>
                      }
                      error={!!errors.corporationNumber}
                      ref={register("corporationNumber").ref}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} />
                </>
              ) : (
                <></>
              )}
              {/* 가맹점, 본점 주소 */}
              <Grid item xs={12} sm={4} sx={{ position: "relative" }}>
                <InputLabel
                  sx={{ position: "absolute", top: 15, fontSize: "14px" }}
                >
                  가맹점 주소
                </InputLabel>
                <Stack direction={"row"} gap={3} alignItems={"center"}>
                  <Button
                    type="button"
                    variant="contained"
                    sx={{
                      color: "white",
                      width: 120
                    }}
                    onClick={() => openPostCodeModal(0)}
                  >
                    주소검색
                  </Button>
                  <Controller
                    name={"postCode"}
                    rules={{ required: "우편번호는 필수값 입니다." }}
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                      <TextField
                        id={"postCode"}
                        size="small"
                        label="우편번호"
                        required
                        value={value}
                        onChange={onChange}
                        fullWidth
                        helperText={
                          <HelperTextMessage>
                            {errors.postCode?.message}
                          </HelperTextMessage>
                        }
                        onInput={numberValidate}
                        error={!!errors.postCode}
                        ref={register("postCode").ref}
                      />
                    )}
                  ></Controller>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name={"mallAddress"}
                  control={control}
                  rules={{
                    required: "주소는 필수값 입니다."
                  }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      size="small"
                      label="주소"
                      required
                      value={value}
                      onChange={onChange}
                      fullWidth
                      helperText={
                        <HelperTextMessage>
                          {errors.mallAddress?.message}
                        </HelperTextMessage>
                      }
                      error={!!errors.mallAddress}
                      ref={register("mallAddress").ref}
                    />
                  )}
                ></Controller>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="detailAddress"
                  size="small"
                  label="상세주소"
                  required
                  {...register("detailAddress", {
                    required: "상세주소는 필수값 입니다."
                  })}
                  fullWidth
                  helperText={
                    <HelperTextMessage>
                      {errors.detailAddress?.message}
                    </HelperTextMessage>
                  }
                  error={!!errors.detailAddress}
                  ref={register("detailAddress").ref}
                />
              </Grid>
              <Grid item xs={12} sm={4} sx={{ position: "relative", mt: 2 }}>
                <Stack
                  direction={"row"}
                  sx={{ position: "absolute", top: -5 }}
                  alignItems={"center"}
                  gap={8.5}
                >
                  <InputLabel sx={{ fontSize: "14px" }}>본점 주소</InputLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isCheckedAddress}
                        onChange={checkBox}
                      />
                    }
                    label="가맹점 주소와 동일"
                    labelPlacement="end"
                  />
                </Stack>
                <Stack direction={"row"} gap={3} alignItems={"center"}>
                  <Button
                    type="button"
                    variant="contained"
                    disabled={isCheckedAddress}
                    sx={{
                      color: "white",
                      width: 120
                    }}
                    onClick={() => openPostCodeModal(1)}
                  >
                    주소검색
                  </Button>
                  <Controller
                    name={"headOfficePostCode"}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        id="headOfficePostCode"
                        size="small"
                        label="우편번호"
                        fullWidth
                        value={isCheckedAddress ? watch("postCode") : value}
                        onChange={onChange}
                        disabled={isCheckedAddress}
                        onInput={numberValidate}
                      />
                    )}
                  ></Controller>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4} mt={2}>
                <Controller
                  name={"headOfficeAddress"}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      id="headOfficeAddress"
                      size="small"
                      label="주소"
                      fullWidth
                      value={isCheckedAddress ? watch("mallAddress") : value}
                      onChange={onChange}
                      disabled={isCheckedAddress}
                    />
                  )}
                ></Controller>
              </Grid>
              <Grid item xs={12} sm={4} mt={2}>
                <Controller
                  name={"headOfficeDetailAddress"}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      id="headOfficeDetailAddress"
                      size="small"
                      label="상세주소"
                      fullWidth
                      value={isCheckedAddress ? watch("detailAddress") : value}
                      onChange={onChange}
                      disabled={isCheckedAddress}
                    />
                  )}
                ></Controller>
              </Grid>
              <PostCode
                openPostCode={openPostCode}
                setOpenPostCode={setOpenPostCode}
                PostCodeHandler={postCodeHandler}
              />

              {/* 업종, 업태 */}
              <Grid item xs={12} sm={3} sx={{ position: "relative" }}>
                <InputLabel
                  sx={{ position: "absolute", top: 12, fontSize: "14px" }}
                >
                  업태&nbsp;
                  <span style={{ fontSize: 14 }}>
                    (등록할 수 있는 업태의 개수는 최대 5개입니다.)
                  </span>
                </InputLabel>
                <TextField
                  label="업태"
                  fullWidth
                  size="small"
                  // {...register("businessConditions")}
                  value={conditionValue}
                  onChange={(e) => setConditionValue(e.target.value)}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleAddBusinessCondition();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button onClick={handleAddBusinessCondition}>
                          추가
                        </Button>
                      </InputAdornment>
                    )
                  }}
                  error={!!errors.businessConditions}
                  helperText={
                    <HelperTextMessage>
                      {errors.businessConditions?.message}
                    </HelperTextMessage>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={9} container alignItems={"center"} gap={2}>
                {businessConditionsFields.map((item, index) => (
                  <Chip
                    key={item.id}
                    label={item.name}
                    onDelete={() => handleDeleteBusinessCondition(index)}
                    sx={{ borderRadius: 1 }}
                  />
                ))}
              </Grid>

              <Grid item xs={12} sm={3} sx={{ position: "relative" }}>
                <InputLabel
                  htmlFor="merchantCategory"
                  sx={{ position: "absolute", top: 12, fontSize: "14px" }}
                >
                  업종&nbsp;
                  <span style={{ fontSize: 14 }}>
                    (등록할 수 있는 업종의 개수는 최대 5개입니다.)
                  </span>
                </InputLabel>

                <TextField
                  label="업종"
                  fullWidth
                  size="small"
                  value={categoryValue}
                  onChange={(e) => setCategoryValue(e.target.value)}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleAddMerchantCategory();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button onClick={handleAddMerchantCategory}>
                          추가
                        </Button>
                      </InputAdornment>
                    )
                  }}
                  error={!!errors.merchantCategory}
                  helperText={
                    <HelperTextMessage>
                      {errors.merchantCategory?.message}
                    </HelperTextMessage>
                  }
                />
              </Grid>

              <Grid item xs={12} sm={9} container alignItems={"center"} gap={2}>
                {merchantCategoryFields.map((item, index) => (
                  <Chip
                    key={item.id}
                    label={item.name}
                    onDelete={() => handleDeleteMerchantCategory(index)}
                    sx={{ borderRadius: 1 }}
                  />
                ))}
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  id="phone"
                  label="전화번호"
                  required
                  size="small"
                  fullWidth
                  {...register("phone", {
                    required: "전화번호는 필수값 입니다.",
                    maxLength: {
                      value: 12,
                      message: "12자 이하로 입력해 주세요."
                    }
                  })}
                  onInput={numberValidate}
                  placeholder="&#39;-&#39; 없이 숫자만 입력해 주세요."
                  helperText={
                    <HelperTextMessage>
                      {errors?.phone?.message}
                    </HelperTextMessage>
                  }
                  error={!!errors.phone}
                  ref={register("phone").ref}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  id="fax"
                  label="팩스번호"
                  size="small"
                  fullWidth
                  {...register("fax", {
                    maxLength: {
                      value: 12,
                      message: "12자 이하로 입력해 주세요."
                    }
                  })}
                  onInput={numberValidate}
                  placeholder="&#39;-&#39; 없이 숫자만 입력해 주세요."
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  id="mailOrderSalesNumber"
                  label="통신판매번호"
                  required
                  size="small"
                  fullWidth
                  {...register("mailOrderSalesNumber", {
                    required: "통신판매번호는 필수값 입니다."
                  })}
                  onInput={numberValidate}
                  helperText={
                    <HelperTextMessage>
                      {errors?.mailOrderSalesNumber?.message}
                    </HelperTextMessage>
                  }
                  error={!!errors.mailOrderSalesNumber}
                  ref={register("mailOrderSalesNumber").ref}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  id="mallUrl"
                  label="홈페이지 주소"
                  required
                  size="small"
                  fullWidth
                  {...register("mallUrl", {
                    required: "홈페이지 주소는 필수값 입니다."
                  })}
                  helperText={
                    <HelperTextMessage>
                      {errors?.mallUrl?.message}
                    </HelperTextMessage>
                  }
                  error={!!errors.mallUrl}
                  ref={register("mallUrl").ref}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  id="mallIp"
                  label="가맹점 IP"
                  required
                  size="small"
                  fullWidth
                  helperText={
                    <HelperTextMessage>
                      {errors.mallIp?.message}
                    </HelperTextMessage>
                  }
                  {...register("mallIp", {
                    required: "가맹점 IP는 필수값 입니다.",
                    pattern: {
                      value:
                        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                      message: "형식에 맞는 IP주소를 입력해 주세요."
                    }
                  })}
                  error={!!errors.mallIp}
                  ref={register("mallIp").ref}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="introductionCompany"
                  label="소개업체 (호스팅사)"
                  size="small"
                  fullWidth
                  {...register("introductionCompany")}
                />
              </Grid>

              <Grid item xs={12} sm={4} sx={{ position: "relative" }}>
                <Controller
                  name="testId"
                  control={control}
                  rules={{
                    required: "테스트용 ID 는 필수값 입니다."
                  }}
                  render={({ field: { value, onChange, ref } }) => (
                    <TextField
                      id="testId"
                      label="테스트용 ID (카드사 심사용)"
                      required
                      size="small"
                      fullWidth
                      value={value}
                      onChange={onChange}
                      helperText={
                        <HelperTextMessage>
                          {errors.testId?.message}
                        </HelperTextMessage>
                      }
                      error={!!errors.testId}
                      inputRef={ref}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title={
                                <Typography variant="subtitle2" fontSize={11}>
                                  ID는 연동 신청하는 쇼핑몰에 로그인 가능한
                                  테스트용 아이디 입니다.
                                </Typography>
                              }
                              placement="right"
                              arrow
                            >
                              <IconButton type="button">
                                <ErrorOutline />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                ></Controller>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="testPassword"
                  control={control}
                  rules={{
                    required: "테스트용 비밀번호는 필수값 입니다."
                  }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      id="testPassword"
                      label="테스트용 비밀번호 (카드사 심사용)"
                      required
                      size="small"
                      fullWidth
                      value={value}
                      onChange={onChange}
                      helperText={
                        <HelperTextMessage>
                          {errors.testPassword?.message}
                        </HelperTextMessage>
                      }
                      error={!!errors.testPassword}
                      ref={register("testPassword").ref}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title={
                                <Typography variant="subtitle2" fontSize={11}>
                                  비밀번호는 연동 신청하는 쇼핑몰에 로그인
                                  가능한 아이디의 비밀번호 입니다.
                                </Typography>
                              }
                              placement="right"
                              arrow
                            >
                              <IconButton type="button">
                                <ErrorOutline />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                ></Controller>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isCheckedTestId}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="비회원 결제가능"
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
          </Box>

          {/* 대표자 등록 ======================================================================================= */}
          <Box
            sx={{
              border: "1px solid #E4E4E7",
              borderRadius: 2,
              overflow: "hidden",
              pb: 3
            }}
          >
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems="center"
              sx={{
                py: 1,
                borderBottom: "1px solid #E4E4E7",
                backgroundColor: "#f7f7f7"
              }}
            >
              <Typography> 대표자 정보</Typography>
            </Stack>
            <Typography variant="body2" sx={{ pl: 3, pt: 2 }}>
              * 공동 대표인 경우 공동 대표 2인 전부 필수 입력
            </Typography>
            {fields.map((item, index) => (
              <Grid container spacing={4} p={3} key={item.id}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id={`merchantOwnerInformation.${index}.name`}
                    label="대표자 한글이름"
                    required
                    size="small"
                    fullWidth
                    {...register(`merchantOwnerInformation.${index}.name`, {
                      required: "대표자명은 필수값 입니다."
                    })}
                    helperText={
                      <HelperTextMessage>
                        {
                          errors?.merchantOwnerInformation?.[index]?.name
                            ?.message
                        }
                      </HelperTextMessage>
                    }
                    error={!!errors.merchantOwnerInformation?.[index]?.name}
                    ref={register(`merchantOwnerInformation.${index}.name`).ref}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="nameEn"
                    label="대표자 영문이름"
                    placeholder="ex) HONG GIL DONG"
                    required
                    size="small"
                    fullWidth
                    {...register(`merchantOwnerInformation.${index}.nameEn`, {
                      required: "대표자 영문이름은 필수값 입니다."
                    })}
                    onInput={engValidate}
                    helperText={
                      <HelperTextMessage>
                        {
                          errors?.merchantOwnerInformation?.[index]?.nameEn
                            ?.message
                        }
                      </HelperTextMessage>
                    }
                    error={!!errors.merchantOwnerInformation?.[index]?.nameEn}
                    ref={
                      register(`merchantOwnerInformation.${index}.nameEn`).ref
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => {
                      append({
                        name: "",
                        birth: "",
                        nationality: "대한민국",
                        address: "",
                        phone: "",
                        postCode: "",
                        detailAddress: ""
                      });
                    }}
                    sx={{
                      color: "white",
                      mr: 2
                    }}
                  >
                    공동 대표자 추가
                  </Button>
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={() => remove(index)}
                    disabled={index === 0}
                  >
                    양식삭제
                  </Button>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    id={`merchantOwnerInformation.${index}.birth`}
                    label="생년월일"
                    required
                    size="small"
                    placeholder="생년월일 8자리를 입력해 주세요."
                    fullWidth
                    {...register(`merchantOwnerInformation.${index}.birth`, {
                      required: "생년월일은 필수값 입니다.",
                      pattern: {
                        value:
                          /^(19[0-9][0-9]|20\d{2})(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/,
                        message: "정확한 생년월일을 입력해 주세요."
                      },
                      maxLength: {
                        value: 8,
                        message: "생년월일 8자리를 입력해 주세요."
                      },
                      minLength: {
                        value: 8,
                        message: "생년월일 8자리를 입력해 주세요."
                      }
                    })}
                    onInput={numberValidate}
                    helperText={
                      <HelperTextMessage>
                        {
                          errors?.merchantOwnerInformation?.[index]?.birth
                            ?.message
                        }
                      </HelperTextMessage>
                    }
                    error={!!errors.merchantOwnerInformation?.[index]?.birth}
                    ref={
                      register(`merchantOwnerInformation.${index}.birth`).ref
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Controller
                    name={`merchantOwnerInformation.${index}.nationality`}
                    control={control}
                    rules={{
                      required: "대표자 국적은 필수값 입니다."
                    }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        label="대표자 국적"
                        size="small"
                        required
                        select
                        value={value}
                        onChange={onChange}
                        fullWidth
                      >
                        {CountryList.map(({ value, text }) => (
                          <MenuItem key={value} value={text}>
                            {text}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  ></Controller>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id={`merchantOwnerInformation.${index}.phone`}
                    label="대표자 연락처"
                    required
                    size="small"
                    fullWidth
                    {...register(`merchantOwnerInformation.${index}.phone`, {
                      required: "대표자 연락처는 필수값 입니다.",
                      maxLength: {
                        value: 12,
                        message: "전화번호는 12자리 이하로 입력 해주세요."
                      }
                    })}
                    onInput={numberValidate}
                    placeholder="&#39;-&#39; 없이 숫자만 입력해 주세요."
                    helperText={
                      <HelperTextMessage>
                        {
                          errors?.merchantOwnerInformation?.[index]?.phone
                            ?.message
                        }
                      </HelperTextMessage>
                    }
                    error={!!errors.merchantOwnerInformation?.[index]?.phone}
                    ref={
                      register(`merchantOwnerInformation.${index}.phone`).ref
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Stack direction={"row"} gap={3} alignItems={"center"}>
                    <Button
                      type="button"
                      variant="contained"
                      sx={{
                        color: "white",
                        width: 120
                      }}
                      onClick={() => openOwnerPostCodeModal(index)}
                    >
                      주소검색
                    </Button>
                    <Controller
                      name={`merchantOwnerInformation.${index}.postCode`}
                      control={control}
                      rules={{
                        required: "우편번호는 필수값 입니다.",
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "숫자만 입력해 주세요."
                        }
                      }}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          size="small"
                          fullWidth
                          label="우편번호"
                          required
                          value={value}
                          onChange={onChange}
                          onInput={numberValidate}
                          helperText={
                            <HelperTextMessage>
                              {
                                errors?.merchantOwnerInformation?.[index]
                                  ?.postCode?.message
                              }
                            </HelperTextMessage>
                          }
                          error={
                            !!errors.merchantOwnerInformation?.[index]?.postCode
                          }
                          ref={
                            register(
                              `merchantOwnerInformation.${index}.postCode`
                            ).ref
                          }
                        />
                      )}
                    ></Controller>
                  </Stack>
                </Grid>
                <PostCode
                  openPostCode={openOwnerPostCode}
                  setOpenPostCode={setOpenOwnerPostCode}
                  PostCodeHandler={ownerAddressHandler}
                />

                <Grid item xs={12} sm={4}>
                  <Controller
                    name={`merchantOwnerInformation.${index}.address`}
                    control={control}
                    rules={{
                      required: "주소는 필수값 입니다."
                    }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        size="small"
                        fullWidth
                        label="주소"
                        required
                        value={value}
                        onChange={onChange}
                        helperText={
                          <HelperTextMessage>
                            {
                              errors?.merchantOwnerInformation?.[index]?.address
                                ?.message
                            }
                          </HelperTextMessage>
                        }
                        error={
                          !!errors.merchantOwnerInformation?.[index]?.address
                        }
                        ref={
                          register(`merchantOwnerInformation.${index}.address`)
                            .ref
                        }
                      />
                    )}
                  ></Controller>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    id="detailAddress"
                    fullWidth
                    size="small"
                    label="상세주소"
                    required
                    {...register(
                      `merchantOwnerInformation.${index}.detailAddress`,
                      {
                        required: "상세주소는 필수값 입니다."
                      }
                    )}
                    helperText={
                      <HelperTextMessage>
                        {
                          errors?.merchantOwnerInformation?.[index]
                            ?.detailAddress?.message
                        }
                      </HelperTextMessage>
                    }
                    error={
                      !!errors.merchantOwnerInformation?.[index]?.detailAddress
                    }
                  />
                </Grid>
              </Grid>
            ))}
          </Box>

          {/* 은행 정보 등록 ======================================================================================= */}
          <Box
            sx={{
              border: "1px solid #E4E4E7",
              borderRadius: 2,
              overflow: "hidden"
            }}
          >
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems="center"
              sx={{
                py: 1,
                borderBottom: "1px solid #E4E4E7",
                backgroundColor: "#f7f7f7"
              }}
            >
              <Typography>은행 정보 등록</Typography>
            </Stack>
            <Typography variant="body2" sx={{ pl: 3, pt: 3 }}>
              * 정산 은행의 경우 개인사업자는 대표자 명의, 법인사업자는 법인
              명의의 계좌만 이용 가능합니다. <br />* 예금주의 경우, 개인사업자는
              대표자 또는 상호명, 법인사업자는 상호명과 일치해야 합니다.
            </Typography>
            <Grid container spacing={4} p={3}>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="bankName"
                  control={control}
                  rules={{
                    required: "은행명은 필수값 입니다."
                  }}
                  render={({ field }) => (
                    <TextField
                      id="bankName"
                      label="은행명"
                      required
                      size="small"
                      select
                      {...field}
                      value={selectedBank.name}
                      onChange={bankSelectHandler}
                      fullWidth
                      error={!!errors.bankName}
                      helperText={
                        <HelperTextMessage>
                          {errors.bankName?.message}
                        </HelperTextMessage>
                      }
                      ref={register("bankName").ref}
                    >
                      {bankNameList?.content?.map((bank) => (
                        <MenuItem key={bank.code} value={bank.name}>
                          {bank.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                ></Controller>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="accountCode"
                  control={control}
                  rules={{
                    required: "은행 계좌번호는 필수값 입니다."
                  }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      id="accountCode"
                      label="은행 계좌번호"
                      required
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                      helperText={
                        <HelperTextMessage>
                          {errors.accountCode?.message}
                        </HelperTextMessage>
                      }
                      onInput={numberValidate}
                      error={!!errors.accountCode}
                      ref={register("accountCode").ref}
                    />
                  )}
                ></Controller>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="accountHolder"
                  control={control}
                  rules={{
                    required: "계좌 소유주는 필수값 입니다."
                  }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      id="accountHolder"
                      label="소유주 이름"
                      required
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                      helperText={
                        <HelperTextMessage>
                          {errors.accountHolder?.message}
                        </HelperTextMessage>
                      }
                      error={!!errors.accountHolder}
                      ref={register("accountHolder").ref}
                    />
                  )}
                ></Controller>
              </Grid>
            </Grid>
          </Box>

          {/* 담당자 등록 ======================================================================================= */}
          <Box
            sx={{
              border: "1px solid #E4E4E7",
              borderRadius: 2,
              overflow: "hidden"
            }}
          >
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems="center"
              sx={{
                py: 1,
                borderBottom: "1px solid #E4E4E7",
                backgroundColor: "#f7f7f7"
              }}
            >
              <Typography>담당자 등록</Typography>
            </Stack>

            <Grid container spacing={3} p={3}>
              {/* 계약 담당자 */}
              <Grid item xs={12} sm={12}>
                <Chip label="계약 담당자" sx={{ fontWeight: "bold" }} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name={`merchantInChargeInformation.${0}.name`}
                  control={control}
                  rules={{
                    required: "담당자 성명은 필수값 입니다."
                  }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      id="name"
                      label="담당자 성명"
                      required
                      fullWidth
                      size="small"
                      value={value}
                      onChange={onChange}
                      helperText={
                        <HelperTextMessage>
                          {
                            errors?.merchantInChargeInformation?.[0]?.name
                              ?.message
                          }
                        </HelperTextMessage>
                      }
                      error={!!errors?.merchantInChargeInformation?.[0]?.name}
                    />
                  )}
                ></Controller>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="email"
                  label="계약 담당자 이메일"
                  size="small"
                  required
                  fullWidth
                  {...register(`merchantInChargeInformation.${0}.email`, {
                    required: "계약 담당자 이메일은 필수값 입니다.",
                    pattern: {
                      value:
                        /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                      message: "형식에 맞지 않는 이메일 주소입니다."
                    }
                  })}
                  helperText={
                    <HelperTextMessage>
                      {errors?.merchantInChargeInformation?.[0]?.email?.message}
                    </HelperTextMessage>
                  }
                  error={!!errors?.merchantInChargeInformation?.[0]?.email}
                  ref={register(`merchantInChargeInformation.${0}.email`).ref}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="phone"
                  label="계약 담당자 연락처"
                  size="small"
                  required
                  fullWidth
                  {...register(`merchantInChargeInformation.${0}.phone`, {
                    required: "계약 담당자 연락처는 필수값 입니다.",
                    maxLength: {
                      value: 12,
                      message: "전화번호는 12자리 이하로 입력 해주세요."
                    }
                  })}
                  onInput={numberValidate}
                  placeholder="&#39;-&#39; 없이 숫자만 입력해 주세요."
                  helperText={
                    <HelperTextMessage>
                      {errors?.merchantInChargeInformation?.[0]?.phone?.message}
                    </HelperTextMessage>
                  }
                  error={!!errors?.merchantInChargeInformation?.[0]?.phone}
                  ref={register(`merchantInChargeInformation.${0}.phone`).ref}
                />
              </Grid>

              {/* 정산 담당자 */}
              <Grid item xs={12} sm={12}>
                <Chip label="정산 담당자" sx={{ fontWeight: "bold" }} />
                <FormControlLabel
                  control={
                    <Checkbox checked={isChecked} onChange={managerCheckBox} />
                  }
                  label="계약 담당자 정보와 동일"
                  labelPlacement="end"
                  sx={{ ml: 4 }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name={`merchantInChargeInformation.${1}.name`}
                  control={control}
                  rules={{
                    required: "정산 담당자명은 필수값 입니다."
                  }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      id={`merchantInChargeInformation.${1}.name`}
                      label="정산 담당자명"
                      required
                      fullWidth
                      size="small"
                      value={
                        isChecked
                          ? watch(`merchantInChargeInformation.${0}.name`)
                          : value
                      }
                      onChange={onChange}
                      helperText={
                        <HelperTextMessage>
                          {
                            errors?.merchantInChargeInformation?.[1]?.name
                              ?.message
                          }
                        </HelperTextMessage>
                      }
                      disabled={isChecked}
                      error={!!errors?.merchantInChargeInformation?.[1]?.name}
                    />
                  )}
                ></Controller>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name={`merchantInChargeInformation.${1}.email`}
                  control={control}
                  rules={{
                    required: "정산 담당자 이메일은 필수값 입니다.",
                    pattern: {
                      value:
                        /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                      message: "형식에 맞지 않는 이메일 주소입니다."
                    }
                  }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      id={`merchantInChargeInformation.${1}.email`}
                      label="정산 담당자 이메일"
                      required
                      fullWidth
                      size="small"
                      value={
                        isChecked
                          ? watch(`merchantInChargeInformation.${0}.email`)
                          : value
                      }
                      onChange={onChange}
                      helperText={
                        <HelperTextMessage>
                          {
                            errors?.merchantInChargeInformation?.[1]?.email
                              ?.message
                          }
                        </HelperTextMessage>
                      }
                      disabled={isChecked}
                      error={!!errors?.merchantInChargeInformation?.[1]?.email}
                    />
                  )}
                ></Controller>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name={`merchantInChargeInformation.${1}.phone`}
                  control={control}
                  rules={{
                    required: "정산 담당자 전화번호는 필수값 입니다.",
                    maxLength: {
                      value: 12,
                      message: "전화번호는 12자리 이하로 입력 해주세요."
                    }
                  }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      id={`merchantInChargeInformation.${1}.phone`}
                      label="정산 담당자 전화번호"
                      required
                      fullWidth
                      size="small"
                      value={
                        isChecked
                          ? watch(`merchantInChargeInformation.${1}.phone`)
                          : value
                      }
                      onChange={onChange}
                      helperText={
                        <HelperTextMessage>
                          {
                            errors?.merchantInChargeInformation?.[1]?.phone
                              ?.message
                          }
                        </HelperTextMessage>
                      }
                      onInput={numberValidate}
                      disabled={isChecked}
                      error={!!errors?.merchantInChargeInformation?.[1]?.phone}
                    />
                  )}
                ></Controller>
              </Grid>

              {/* 세금 계산 담당자 */}
              <Grid item xs={12} sm={12}>
                <Chip label="세금 계산 담당자" sx={{ fontWeight: "bold" }} />
                <FormControlLabel
                  control={
                    <Checkbox checked={isCheckedTax} onChange={taxCheckBox} />
                  }
                  label="정산 담당자 정보와 동일"
                  labelPlacement="end"
                  sx={{ ml: 1 }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name={`merchantInChargeInformation.${2}.name`}
                  control={control}
                  rules={{
                    required: "세금 계산 담당자명은 필수값 입니다."
                  }}
                  defaultValue=""
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      id={`merchantInChargeInformation.${2}.name`}
                      label="세금 담당자명"
                      required
                      fullWidth
                      size="small"
                      value={
                        isCheckedTax
                          ? watch(`merchantInChargeInformation.${1}.name`)
                          : value
                      }
                      onChange={onChange}
                      helperText={
                        <HelperTextMessage>
                          {
                            errors?.merchantInChargeInformation?.[2]?.name
                              ?.message
                          }
                        </HelperTextMessage>
                      }
                      disabled={isCheckedTax}
                      error={!!errors?.merchantInChargeInformation?.[2]?.name}
                    />
                  )}
                ></Controller>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name={`merchantInChargeInformation.${2}.email`}
                  control={control}
                  rules={{
                    required: "세금 계산 담당자 이메일은 필수값 입니다.",
                    pattern: {
                      value:
                        /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                      message: "형식에 맞지 않는 이메일 주소입니다."
                    }
                  }}
                  defaultValue=""
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      id={`merchantInChargeInformation.${2}.email`}
                      label="세금 담당자 이메일"
                      required
                      fullWidth
                      size="small"
                      value={
                        isCheckedTax
                          ? watch(`merchantInChargeInformation.${1}.email`)
                          : value
                      }
                      onChange={onChange}
                      helperText={
                        <HelperTextMessage>
                          {
                            errors?.merchantInChargeInformation?.[2]?.email
                              ?.message
                          }
                        </HelperTextMessage>
                      }
                      disabled={isCheckedTax}
                      error={!!errors?.merchantInChargeInformation?.[2]?.email}
                    />
                  )}
                ></Controller>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name={`merchantInChargeInformation.${2}.phone`}
                  control={control}
                  rules={{
                    required: "세금 담당자 전화번호는 필수값 입니다.",
                    maxLength: {
                      value: 12,
                      message: "전화번호는 12자리 이하로 입력 해주세요."
                    }
                  }}
                  defaultValue=""
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      id={`merchantInChargeInformation.${2}.phone`}
                      label="세금 담당자 연락처"
                      required
                      fullWidth
                      size="small"
                      value={
                        isCheckedTax
                          ? watch(`merchantInChargeInformation.${1}.phone`)
                          : value
                      }
                      onChange={onChange}
                      helperText={
                        <HelperTextMessage>
                          {
                            errors?.merchantInChargeInformation?.[2]?.phone
                              ?.message
                          }
                        </HelperTextMessage>
                      }
                      onInput={numberValidate}
                      disabled={isCheckedTax}
                      error={!!errors?.merchantInChargeInformation?.[2]?.phone}
                    />
                  )}
                ></Controller>
              </Grid>
            </Grid>
          </Box>

          {/* 파일 등록 ======================================================================================= */}
          <Box
            sx={{
              border: "1px solid #E4E4E7",
              borderRadius: 2,
              overflow: "hidden"
            }}
          >
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems="center"
              sx={{
                py: 1,
                borderBottom: "1px solid #E4E4E7",
                backgroundColor: "#f7f7f7"
              }}
            >
              <Typography>파일 등록</Typography>
            </Stack>
            <Box pl={3} pt={2}>
              <Typography variant="body2" sx={{ mt: 1.5 }}>
                * 제출 서류의 첨부파일 최대 용량은 10MB 입니다. <br />* 제출
                서류의 첨부 파일 종류는 jpg, jpeg, gif, png, pdf 입니다.
              </Typography>
            </Box>

            {/* 개인 사업자 파일 등록 ---------------------------------------------------------------------------- */}
            {currentBusinessType === "개인" ? (
              <Grid container spacing={2} p={3}>
                <Grid item xs={12} sm={12}>
                  <InputLabel>사업자등록증</InputLabel>
                  <Stack direction={"row"} alignItems="center">
                    <TextField
                      size="small"
                      sx={{ width: 350 }}
                      error={!!errors.businessLicense}
                      disabled
                      {...register("businessLicense", {
                        required: "사업자등록증은 필수 파일 입니다."
                      })}
                    />
                    <Button
                      component="label"
                      sx={{
                        mx: 2,
                        background: "#eee",
                        color: "black",
                        p: 1
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*, .pdf"
                        hidden
                        onChange={사업자등록증}
                      />
                      {파일선택텍스트("businessLicense")}
                    </Button>
                    {errors.businessLicense && (
                      <Typography variant="caption" color="error">
                        {errors.businessLicense.message}
                      </Typography>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <InputLabel>통장 사본</InputLabel>
                    <Typography sx={{ fontSize: "12px", color: "gray" }}>
                      (대표자 명의)
                    </Typography>
                  </Stack>
                  <TextField
                    size="small"
                    disabled
                    sx={{ width: 350 }}
                    error={!!errors.bankBookCopy}
                    {...register("bankBookCopy", {
                      required: "통장 사본은 필수 파일 입니다."
                    })}
                  />
                  <Button
                    component="label"
                    sx={{ mx: 2, background: "#eee", color: "black", p: 1 }}
                  >
                    <input
                      type="file"
                      accept="image/*, .pdf"
                      hidden
                      onChange={통장사본}
                    />
                    {파일선택텍스트("bankBookCopy")}
                  </Button>
                  {errors.bankBookCopy && (
                    <Typography variant="caption" color="error">
                      {errors.bankBookCopy.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <InputLabel>대표자 인감증명서</InputLabel>
                    <Typography sx={{ fontSize: "12px", color: "gray" }}>
                      (원본 1부)
                    </Typography>
                    <Typography sx={{ fontSize: "12px", color: "error.main" }}>
                      * 서류는 제출기한 기준 최근 3개월 이내 발급 받은 것
                    </Typography>
                  </Stack>
                  <TextField
                    size="small"
                    disabled
                    sx={{ width: 350 }}
                    error={!!errors.sealCertificate}
                    {...register("sealCertificate", {
                      required: "대표자 인감증명서는 필수 파일 입니다."
                    })}
                  />
                  <Button
                    component="label"
                    sx={{
                      mx: 2,
                      background: "#eee",
                      color: "black",
                      p: 1
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*, .pdf"
                      hidden
                      onChange={인감증명서}
                    />
                    {파일선택텍스트("sealCertificate")}
                  </Button>
                  {errors.sealCertificate && (
                    <Typography variant="caption" color="error">
                      {errors.sealCertificate.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <InputLabel>대표자 신분증 (앞면)</InputLabel>
                    <Typography sx={{ fontSize: "12px", color: "gray" }}>
                      (앞면 사본 1부)
                    </Typography>
                  </Stack>
                  <TextField
                    size="small"
                    disabled
                    sx={{ width: 350 }}
                    error={!!errors.idCardFront}
                    {...register("idCardFront", {
                      required: "대표자 신분증은 필수 파일 입니다."
                    })}
                  />
                  <Button
                    component="label"
                    sx={{ background: "#eee", color: "black", p: 1, mx: 2 }}
                  >
                    <input
                      type="file"
                      accept="image/*, .pdf"
                      hidden
                      onChange={신분증앞}
                    />
                    {파일선택텍스트("idCardFront")}
                  </Button>
                  {errors.idCardFront && (
                    <Typography variant="caption" color="error">
                      {errors.idCardFront.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <InputLabel>대표자 신분증 (뒷면)</InputLabel>
                    <Typography sx={{ fontSize: "12px", color: "gray" }}>
                      (뒷면 사본 1부)
                    </Typography>
                  </Stack>
                  <TextField
                    size="small"
                    disabled
                    sx={{ width: 350 }}
                    error={!!errors.idCardBack}
                    {...register("idCardBack", {
                      required: "대표자 신분증은 필수 파일 입니다."
                    })}
                  />
                  <Button
                    component="label"
                    sx={{ background: "#eee", color: "black", p: 1, mx: 2 }}
                  >
                    <input
                      type="file"
                      accept="image/*, .pdf"
                      hidden
                      onChange={신분증뒤}
                    />
                    {파일선택텍스트("idCardBack")}
                  </Button>
                  {errors.idCardBack && (
                    <Typography variant="caption" color="error">
                      {errors.idCardBack.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <InputLabel>영중소 등급확인서</InputLabel>
                  <TextField
                    size="small"
                    sx={{ width: 350 }}
                    disabled
                    error={!!errors.gradeConfirmation}
                    {...register("gradeConfirmation")}
                  />
                  <Button
                    component="label"
                    sx={{ mx: 2, background: "#eee", color: "black", p: 1 }}
                  >
                    <input
                      type="file"
                      accept="image/*, .pdf"
                      hidden
                      onChange={등급확인서}
                    />
                    {파일선택텍스트("gradeConfirmation")}
                  </Button>
                  {errors.gradeConfirmation && (
                    <Typography variant="caption" color="error">
                      {errors.gradeConfirmation.message}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            ) : (
              <>
                {/* 법인 사업자 파일 등록 ---------------------------------------------------------------------------- */}
                <Grid container spacing={3} p={3}>
                  <Grid item xs={12} sm={12}>
                    <InputLabel>사업자등록증</InputLabel>
                    <Stack direction={"row"} alignItems="center">
                      <TextField
                        size="small"
                        sx={{ width: 350 }}
                        error={!!errors.businessLicense}
                        disabled
                        {...register("businessLicense", {
                          required: "사업자등록증은 필수 파일 입니다."
                        })}
                      />
                      <Button
                        component="label"
                        sx={{
                          mx: 2,
                          background: "#eee",
                          color: "black",
                          p: 1
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*, .pdf"
                          hidden
                          onChange={사업자등록증}
                        />
                        {파일선택텍스트("businessLicense")}
                      </Button>
                      {errors.businessLicense && (
                        <Typography variant="caption" color="error">
                          {errors.businessLicense.message}
                        </Typography>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <InputLabel>통장 사본</InputLabel>
                      <Typography sx={{ fontSize: "12px", color: "gray" }}>
                        (법인 명의)
                      </Typography>
                    </Stack>
                    <TextField
                      size="small"
                      disabled
                      sx={{ width: 350 }}
                      error={!!errors.bankBookCopy}
                      {...register("bankBookCopy", {
                        required: "통장 사본은 필수 파일 입니다."
                      })}
                    />
                    <Button
                      component="label"
                      sx={{
                        mx: 2,
                        background: "#eee",
                        color: "black",
                        p: 1
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*, .pdf"
                        hidden
                        onChange={통장사본}
                      />
                      {파일선택텍스트("bankBookCopy")}
                    </Button>
                    {errors.bankBookCopy && (
                      <Typography variant="caption" color="error">
                        {errors.bankBookCopy.message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <InputLabel>법인인감증명서</InputLabel>
                    <Stack direction={"row"} gap={2} alignItems={"center"}>
                      <TextField
                        size="small"
                        sx={{ width: 350 }}
                        disabled
                        error={!!errors.corporateSealCertificate}
                        {...register("corporateSealCertificate", {
                          required: "법인인감증명서는 필수 파일 입니다."
                        })}
                      />
                      <Button
                        component="label"
                        sx={{
                          background: "#eee",
                          color: "black",
                          p: 1
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*, .pdf"
                          hidden
                          onChange={법인인감증명서}
                        />
                        {파일선택텍스트("corporateSealCertificate")}
                      </Button>
                      {errors.corporateSealCertificate && (
                        <Typography variant="caption" color="error">
                          {errors.corporateSealCertificate.message}
                        </Typography>
                      )}
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                      <InputLabel>사용인감계</InputLabel>
                      <Typography sx={{ fontSize: "12px", color: "gray" }}>
                        (원본 1부)
                      </Typography>
                      <Typography
                        sx={{ fontSize: "12px", color: "error.main" }}
                      >
                        * 서류는 제출기한 기준 최근 3개월 이내 발급 받은 것
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} gap={2} alignItems={"center"}>
                      <TextField
                        size="small"
                        sx={{ width: 350 }}
                        disabled
                        {...register("사용인감계")}
                      />
                      <Button
                        component="label"
                        sx={{
                          mr: 2,
                          background: "#eee",
                          color: "black",
                          p: 1
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*, .pdf"
                          hidden
                          onChange={사용인감계}
                        />
                        {파일선택텍스트("사용인감계")}
                      </Button>
                      {errors.사용인감계 && (
                        <Typography variant="caption" color="error">
                          {errors.사용인감계.message}
                        </Typography>
                      )}
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <InputLabel>등기사항전부증명서</InputLabel>
                      <Typography sx={{ fontSize: "12px", color: "gray" }}>
                        (원본 1부)
                      </Typography>
                      <Typography
                        sx={{ fontSize: "12px", color: "error.main" }}
                      >
                        * 서류는 제출기한 기준 최근 3개월 이내 발급 받은 것
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"}>
                      <TextField
                        size="small"
                        sx={{ width: 350 }}
                        disabled
                        error={!!errors.corporateRegistration}
                        {...register("corporateRegistration", {
                          required: "등기사항전부증명서는 필수 파일입니다."
                        })}
                      />
                      <Button
                        component="label"
                        sx={{
                          mx: 2,
                          background: "#eee",
                          color: "black",
                          p: 1
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*, .pdf"
                          hidden
                          onChange={등기사항전부증명서}
                        />
                        {파일선택텍스트("corporateRegistration")}
                      </Button>
                      {errors.corporateRegistration && (
                        <Typography variant="caption" color="error">
                          {errors.corporateRegistration.message}
                        </Typography>
                      )}
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <InputLabel>실제소유자확인서</InputLabel>
                      <Typography sx={{ fontSize: "12px", color: "gray" }}>
                        (주주명부, 정관, 사원명부 중 택 1)
                      </Typography>
                    </Stack>
                    <TextField
                      size="small"
                      sx={{ width: 350 }}
                      disabled
                      error={!!errors.actualOwnerConfirmation}
                      {...register("actualOwnerConfirmation", {
                        required: "실제소유자확인서는 필수 파일입니다."
                      })}
                    />
                    <Button
                      component="label"
                      sx={{
                        mx: 2,
                        background: "#eee",
                        color: "black",
                        p: 1
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*, .pdf"
                        hidden
                        onChange={실제소유자확인서}
                      />
                      {파일선택텍스트("actualOwnerConfirmation")}
                    </Button>
                    {errors.actualOwnerConfirmation && (
                      <Typography variant="caption" color="error">
                        {errors.actualOwnerConfirmation.message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                      <InputLabel>대표자 신분증 (앞면)</InputLabel>
                      <Typography sx={{ fontSize: "12px", color: "gray" }}>
                        (앞면 사본 1부)
                      </Typography>
                    </Stack>
                    <TextField
                      size="small"
                      disabled
                      sx={{ width: 350 }}
                      error={!!errors.idCardFront}
                      {...register("idCardFront", {
                        required: "대표자 신분증은 필수 파일 입니다."
                      })}
                    />
                    <Button
                      component="label"
                      sx={{
                        background: "#eee",
                        color: "black",
                        p: 1,
                        mx: 2
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*, .pdf"
                        hidden
                        onChange={신분증앞}
                      />
                      {파일선택텍스트("idCardFront")}
                    </Button>
                    {errors.idCardFront && (
                      <Typography variant="caption" color="error">
                        {errors.idCardFront.message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                      <InputLabel>대표자 신분증 (뒷면)</InputLabel>
                      <Typography sx={{ fontSize: "12px", color: "gray" }}>
                        (뒷면 사본 각 1부)
                      </Typography>
                    </Stack>
                    <TextField
                      size="small"
                      disabled
                      sx={{ width: 350 }}
                      error={!!errors.idCardBack}
                      {...register("idCardBack", {
                        required: "대표자 신분증은 필수 파일 입니다."
                      })}
                    />
                    <Button
                      component="label"
                      sx={{
                        background: "#eee",
                        color: "black",
                        p: 1,
                        mx: 2
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*, .pdf"
                        hidden
                        onChange={신분증뒤}
                      />
                      {파일선택텍스트("idCardBack")}
                    </Button>
                    {errors.idCardBack && (
                      <Typography variant="caption" color="error">
                        {errors.idCardBack.message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <InputLabel>영중소 등급확인서</InputLabel>
                    <TextField
                      size="small"
                      sx={{ width: 350 }}
                      disabled
                      error={!!errors.gradeConfirmation}
                      {...register("gradeConfirmation")}
                    />
                    <Button
                      component="label"
                      sx={{
                        mx: 2,
                        background: "#eee",
                        color: "black",
                        p: 1
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*, .pdf"
                        hidden
                        onChange={등급확인서}
                      />
                      {파일선택텍스트("gradeConfirmation")}
                    </Button>
                    {errors.gradeConfirmation && (
                      <Typography variant="caption" color="error">
                        {errors.gradeConfirmation.message}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
          <Button
            type="submit"
            variant="contained"
            sx={{ color: "white" }}
            fullWidth
            disabled={buttonDisable}
          >
            가맹 등록
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default AdminRegistMerechantForm;
