import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Box, Grid, MenuItem, Paper, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useQuery } from "@tanstack/react-query";
import koLocale from "date-fns/locale/ko";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useRecoilState, useSetRecoilState } from "recoil";
import { getMerchantNames } from "../../../api/v2/Payments";
import {
  getPurchasesCardTotal,
  getPurchasesTotal
} from "../../../api/v2/Purchases";
import { PurchaseDetailParams } from "../../../api/v2/Purchases/types";
import AdminLayout from "../../../components/Layout/Admin/Layout";
import LoadingButton from "../../../components/LoadingButton";
import CardsTotal from "../../../components/Purchases/CardsTotal";
import PurchaseDetail from "../../../components/Purchases/PurchaseDetail";
import PurchaseTotal from "../../../components/Purchases/PurchaseTotal";
import Title from "../../../components/Title";
import { getDate } from "../../../libs/get-date";
import { getToday } from "../../../libs/get-today";
import isModalOpenAtom from "../../../recoil/isModalOpen";
import PurchasesState, {
  PurchasesDetailState
} from "../../../recoil/purchases/atom";
import { CARD_COMPANY } from "./Payments";
import useRenewal from "../../../hooks/useRenewal";

interface PurchasesForm {
  startDate: string;
  endDate: string;
  cardCompany: string;
  status: string;
  mallId: string;
  mallName: string;
  paymentKey: string;
  approvalNum: string;
}

const Purchases = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ===============================================================================================
  // 리코일 스테이트
  // ===============================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);
  const [purchasesDetail, setPurchasesDetail] =
    useRecoilState(PurchasesDetailState);
  const [purchasesTotal, setPurchasesTotal] = useRecoilState(PurchasesState);

  // ===============================================================================================
  // 리액트 훅 폼
  // ===============================================================================================
  // form 기본 값
  const defaultFormValues = {
    startDate: getToday(),
    endDate: getToday(),
    cardCompany: "기본",
    status: "DEFAULT",
    mallId: "",
    mallName: "",
    paymentKey: "",
    approvalNum: ""
  };

  const { register, handleSubmit, control, reset } = useForm<PurchasesForm>({
    defaultValues: {
      ...defaultFormValues
    }
  });
  // ===============================================================================================
  // 리코일 스테이트 초기화
  // ===============================================================================================
  useEffect(() => {
    setPurchasesTotal(defaultFormValues);
    setPurchasesDetail({
      ...defaultFormValues,
      page: 0,
      pageSize: 50,
      sort: purchasesDetail.sort
    });
  }, []);

  // ===============================================================================================
  // 리액트 쿼리
  // ===============================================================================================
  // 현재 운영중인 가맹점 목록 조회
  const { data: getMerchantsNameData } = useQuery(["/merchants/names"], () =>
    getMerchantNames("")
  );

  // 가맹점별 총합계 조회
  const { isLoading, data } = useQuery(
    ["/purchase/total/merchant", purchasesTotal],
    () => getPurchasesTotal(purchasesTotal),
    {
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      },
      onSuccess: () => {
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      }
    }
  );

  // 카드사 총합계 조회
  const { isLoading: cardTotalIsLoading, data: cardTotalData } = useQuery(
    ["/purchase/total/card", purchasesTotal],
    () => getPurchasesCardTotal(purchasesTotal),
    {
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      },
      refetchOnWindowFocus: false,
      select: (data) => ({
        ...data,
        content: data.content.map((data: any) => ({
          ...data,
         
        }))
      })
    }
  );

  const lastData =
    cardTotalData && cardTotalData.content.length > 0
      ? cardTotalData.content[cardTotalData.content.length - 1]
      : null;

  // ===============================================================================================
  // form submit 처리 함수
  // ===============================================================================================
  const handleForm = (data: PurchasesForm) => {
    // 날짜 검증 1 : 날짜 값이 있는지 확인
    const isInvalidData =
      data.startDate?.includes("NaN") || data.endDate?.includes("NaN");

    // 날짜 검증 2 : 날짜 앞뒤가 맞는지 확인
    const isInvaildPeriod = data.startDate > data.endDate;

    // 날짜 검증 실패시 에러 모달
    if (isInvalidData || isInvaildPeriod) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "매입 일자를 확인해주세요."
      });
      return;
    }

    // 쿼리 데이터 설정
    setPurchasesDetail((prevValue: PurchaseDetailParams) => ({
      ...prevValue,
      ...data,
      page: 0
    }));
    setPurchasesTotal(data);
  };

  // [form 초기화] =================================================================================
  const handleReset = () => {
    reset(defaultFormValues);
  };

  return (
    <>
      <Title title="승인·취소" />
      <AdminLayout>
        <Stack spacing={3}>
          <Paper
            sx={{
              p: 3,
              border: "1px solid #F2F3F5",
              borderRadius: 3
            }}
          >
            <form onSubmit={handleSubmit(handleForm)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <LocalizationProvider
                      adapterLocale={koLocale}
                      dateAdapter={AdapterDateFns}
                    >
                      <Grid item xs={2}>
                        <Controller
                          name="startDate"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              label="시작 날짜"
                              inputFormat="yyyy-MM-dd"
                              value={value}
                              onChange={(value) => onChange(getDate(value))}
                              renderInput={(param) => (
                                <TextField
                                  fullWidth
                                  size="small"
                                  required
                                  {...param}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Controller
                          name="endDate"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              label="종료 날짜"
                              inputFormat="yyyy-MM-dd"
                              value={value}
                              onChange={(value) => onChange(getDate(value))}
                              renderInput={(param) => (
                                <TextField
                                  fullWidth
                                  size="small"
                                  required
                                  {...param}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                    </LocalizationProvider>
                    <Grid item xs={2}>
                      <Controller
                        name="cardCompany"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            id="input-card-company"
                            label="카드사"
                            required
                            fullWidth
                            size="small"
                            select
                            value={value}
                            onChange={onChange}
                          >
                            {CARD_COMPANY.map((c: any) => (
                              <MenuItem key={c.value} value={c.value}>
                                {c.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="status"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            id="input-status"
                            label="거래 상태"
                            required
                            fullWidth
                            size="small"
                            select
                            value={value}
                            onChange={onChange}
                          >
                            <MenuItem value="DEFAULT">전체</MenuItem>
                            <MenuItem value="COMPLETE">승인</MenuItem>
                            <MenuItem value="CANCEL">취소</MenuItem>
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={1}
                      >
                        <LoadingButton
                          icon={<SearchIcon />}
                          size="medium"
                          fullWidth={false}
                          loading={false}
                        >
                          조회
                        </LoadingButton>
                        <Button
                          variant="outlined"
                          endIcon={<RestartAltIcon />}
                          onClick={handleReset}
                        >
                          조건 초기화
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Autocomplete
                    id="input-mallName"
                    options={getMerchantsNameData?.content ?? []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="input-mallName"
                        label="가맹점명"
                        inputProps={{
                          ...params.inputProps
                        }}
                        fullWidth
                        size="small"
                        {...register("mallName")}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    id="input-mallId"
                    label="가맹점 ID"
                    fullWidth
                    size="small"
                    {...register("mallId")}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    id="input-paymentKey"
                    label="거래고유KEY"
                    fullWidth
                    size="small"
                    {...register("paymentKey")}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    id="input-approvalNum"
                    label="승인 번호"
                    fullWidth
                    size="small"
                    {...register("approvalNum")}
                  />
                </Grid>
              </Grid>
            </form>
          </Paper>

          {/* 가맹점별 합계 */}
          <PurchaseTotal data={data?.content ?? []} isLoading={isLoading} />

          {/* 카드사별 합계 */}
          <CardsTotal data={cardTotalData} isLoading={cardTotalIsLoading} />

          {/* 승인.취소 상세 조회 */}
          <PurchaseDetail totalData={lastData} />
        </Stack>
      </AdminLayout>
    </>
  );
};

export default Purchases;
