import { instanceWithAuth } from "../Instance";
import { CommonApi } from "../type";
import {
  GetAllowedHostParams,
  GetAllowedHostType,
  GetApiKey,
  GetApiKeyParams,
  PostAllowedHostParams,
  PutAllowedHostPrams
} from "./types";

/**
 *  가맹점 key 정보 조회
 */
export const getMerchantApiKey = async (params: GetApiKeyParams) => {
  const { data } = await instanceWithAuth.get<CommonApi<GetApiKey[]>>(
    "/merchant/key",
    { params }
  );

  return data;
};

/**
 *  가맹점 허용 도메인 조회
 */
export const getAllowedDomains = async (params: GetAllowedHostParams) => {
  const url = `/merchants/${params.merchantId}/allowed-domains/${params.merchantAllowedDomainId}`;
  const { data } = await instanceWithAuth.get<CommonApi<GetAllowedHostType>>(
    url
  );
  return data;
};

/**
 *  가맹점 허용 도메인 생성
 */
export const postAllowedDomains = async (params: PostAllowedHostParams) => {
  const url = `/merchants/${params.merchantId}/allowed-domains`;
  const { data } = await instanceWithAuth.post<CommonApi<boolean>>(url, params);
  return data;
};

/**
 *  가맹점 허용 도메인 수정
 */
export const putAllowedDomains = async (params: PutAllowedHostPrams) => {
  const url = `/merchants/${params.merchantId}/allowed-domains/${params.merchantAllowedDomainId}`;
  const { data } = await instanceWithAuth.put<CommonApi<boolean>>(url, params);
  return data;
};
