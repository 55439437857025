import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import isModalOpenAtom from "../../recoil/isModalOpen";
import CreateDomainForm from "./CreateDomainForm";
import { getAllowedDomains } from "../../api/ApiKey";

interface AllowedDomainModalProps {
  isOpen: boolean;
  onClose: () => void;
  merchantId: string;
  merchantAllowedDomainId: string;
}

const AllowedDomainModal = ({
  isOpen,
  onClose,
  merchantId,
  merchantAllowedDomainId
}: AllowedDomainModalProps) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [isEdit, setIsEdit] = useState(false);

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 허용된 도메인 조회
  const { data: domains } = useQuery(
    [`/merchants/${merchantId}/allowed-domains/${merchantAllowedDomainId}`],
    () => getAllowedDomains({ merchantId, merchantAllowedDomainId }),
    {
      enabled: !!isOpen && merchantAllowedDomainId !== null,
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  // ===================================================================================================================
  // 버튼 렌더링 조건 로직
  // ===================================================================================================================
  // 조회된 데이터가 없거나 오류가 있을 때 조건 처리
  const hasAllowedDomains =
    domains?.content.domains.length && domains.content.domains.length > 0;
  const showForm =
    domains?.content.merchantAllowedDomainId && !hasAllowedDomains;

  // 등록 버튼: 등록된 호스트와 아이디가 없는 경우
  const showRegisterButton =
    !isEdit && !hasAllowedDomains && !domains?.content.merchantAllowedDomainId;

  // ===================================================================================================================
  // 모달 닫기 핸들러 (isEdit 리셋 포함)
  // ===================================================================================================================
  const handleClose = () => {
    setIsEdit(false);
    onClose();
  };

  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth="xs">
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          가맹점 허용 도메인
          <Box>
            {hasAllowedDomains && !isEdit ? (
              <Button
                variant="outlined"
                sx={{ mr: 2 }}
                onClick={() => setIsEdit(true)}
              >
                수정하기
              </Button>
            ) : null}
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {hasAllowedDomains && !isEdit ? (
            <TableContainer
              sx={{
                mt: 2,
                border: "1px solid #f2f2f2",
                borderRadius: 2,
                overflow: "hidden"
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        background: "#FBFBFB",
                        whiteSpace: "nowrap"
                      }}
                    >
                      허용 도메인 목록
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {domains.content.domains.map((domain, index) => (
                    <TableRow key={index}>
                      <TableCell>{domain}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : isEdit || showForm ? (
            <CreateDomainForm
              merchantId={merchantId}
              domainData={domains?.content.domains}
              merchantAllowedDomainId={domains?.content.merchantAllowedDomainId}
              onClose={handleClose}
            />
          ) : showRegisterButton ? (
            <>
              <DialogContentText sx={{ mb: 2 }}>
                등록된 도메인이 없습니다. <br />
                도메인을 등록하시려면 '등록하기' 버튼을 눌러주세요.
              </DialogContentText>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => setIsEdit(true)}
              >
                등록하기
              </Button>
            </>
          ) : null}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AllowedDomainModal;
